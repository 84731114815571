// ==========================================================================
// Breadcrumb
// ==========================================================================

.breadcrumb {
  position: relative;
  background-color: $c-white;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0 em(20px) 0 em(60px);
  display: none;

  @media (min-width: $screen-sm) {
      display: block;
  }

  &__home {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: em(60px);
    height: 100%;
    @extend %fa;
    @extend .fa-home;
    border-right: 1px solid $gray-lighter;
    transition: background-color .3s;

    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-indent: -9999px;
      z-index: 1;
    }

    &:before {
      position: absolute;
      @extend %centerXY;
      font-size: em(24px);
    }

    &:hover {
      background-color: darken($c-white, 10%);
    }
  }

  &__path {
    display: inline-block;
    margin: 0;
    padding: em(25px) em($grid-gutter-width/2) em(16px);

    li {
      display: inline-block;
      font-size: em(14px);
      font-weight: 300;
      text-transform: uppercase;

      &:last-child {
          font-weight: bold;
      }

      &:after {
        content: '/';
        margin: 0 em(5px, 14px);
        font-weight: 300;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  @media (min-width: $screen-sm) {
    padding-left: em(100px);

    &__home {
      width: em(100px);
    }
  }

  @media (min-width: $screen-md) {
    padding-right: em(320px);
  }
}

// No Home button and no Title, centered in container
.breadcrumb--alt {
    padding-top: em(33px);
    padding-bottom: em(33px);
    margin-top: $grid-gutter-width;
    padding-right: 0;
    padding-left: 0;

    .social--header {
        right: inherit;
        display: block;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        @media (min-width: $screen-sm) {
            width: auto;
        }

        &__button {
            width: em(55px);
            @media (min-width: $screen-sm) {
                width: em(70px);
            }
        }
    }
}

// ==========================================================================
// Sections
// ==========================================================================

.section {
	&--white {
		color: white;

		&:after {
			background: white;
		}
	}

	&__title {
		font-size: 1.5rem;
        margin-bottom: 50px;
		color: $gray-base;
		font-weight: 300;
		text-align: center;
		position: relative;
		text-transform: uppercase;
		
		@media (min-width: $screen-sm) {
			font-size: 2.2rem;
		}

		&:after {
			content: "";
			height: 2px;
			width: 74px;
			position: absolute;
			bottom: -8px;
			left: 50%;
			margin-left: -36px;
			@extend %back-grad;
		}
	}


	&__subtitle {
		@extend .h3;
		position: relative;
		margin-bottom: 27px;

		&:after {
			content: "";
			height: 1px;
			width: 100%;
			position: absolute;
			bottom: -8px;
			left: 0%;
			background: $gray-lighter;
		}
	}

	&__infos {
		position: relative;
		color: $text-color;
		padding-top: $padding-large-vertical; //12px
		padding-bottom: $padding-large-vertical; //12px

		&--forms {
			text-align: left;
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	&__forms {
		@extend .h2;
		position: relative;
		text-align: left;
		font-size: $fs-base; //21px
		border-bottom: 1px solid $gray-lighter;
	}
}

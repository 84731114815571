.not-found {
	margin-bottom: 8rem;
	text-align: center;

	h2 {
		display: inline-block;
		font-family: $ff-base;
		font-size: 72px;
		font-weight: bold;
		text-transform: uppercase;

		@media screen and (-webkit-min-device-pixel-ratio:0) {
			color: rgba(255,255,255,0.1);
			-webkit-background-clip: text;
			background-clip: text;
			background-size: cover;
			background-image: url(../images/bg-color.jpg);
		}
	}

	p {
		margin: 40px 0 80px;
	}
}

// ==========================================================================
// Article
// ==========================================================================

.article {

  a {
    cursor: pointer;
  }

  &__title {
    @extend .h2;
    font-weight: 600;

    small {
      color: $gray-light;
      display: block;

      span {
          display: block;
          margin-bottom: 5px;
      }
    }
  }

  &__heading {
      @extend .h1;
      font-size: 24px;
      margin-top: em(45px, 36px);
      padding-bottom: em(35px, 36px);
      border-bottom: 1px solid $gray-lighter;
      font-weight: 600;
      text-align: center;

      small {
        margin-top: .625rem;
        font-size: 60%;
        font-weight: 600;
        color: $gray-light;
        display: block;
      }

      @media (min-width: $screen-xs) {
          font-size: 36px;

          small {
              font-size: 45%;
          }
      }
  }

  &__banner {
      @extend .h1;
      color: $c-white;
      font-size: 24px;
      margin-top: em(45px, 36px);
      padding-bottom: em(35px, 36px);
      font-weight: 600;
      text-align: center;

      small {
        margin-top: .625rem;
        font-size: 60%;
        font-weight: 600;
        color: $c-white;
        display: block;
      }

      @media (min-width: $screen-xs) {
        font-size: 36px;

        small {
            font-size: 45%;
        }
      }
  }

// ==========================================================================
// Article / Preview
// ==========================================================================

  &--preview {
    position: relative;
    margin-bottom: em($grid-gutter-width);
    border: 1px solid $gray-lighter;
    background-color: $c-white;

    a {
      cursor: pointer;
    }    

  	.article__img,
    img {
        width: 100%;
        max-width: 100%;
    }

  	.article__caption {
      padding: 0 em($grid-gutter-width/2) em($grid-gutter-width/2);
    }

    .article__title {
      font-size: em(20px);
	  text-transform: none;

		@media (min-width: $screen-sm) {
			font-size: em(22px);
		}  	  


  		small {
            margin-top: 10px;
  			font-weight: 700;
      }

        a:hover {
            color: $brand-primary;
            text-decoration: none;
        }
  	}

    .article__content {
      margin: 0;
      font-family: $ff-alt;
      font-weight: 400;
      line-height: 1.1;
      display: none;

      @media (min-width: $screen-sm) {
        display: block;
      }        
    }
 
}

&--horizontal {
	display: flex;
	flex-direction: column;

	.article__title {
		margin-bottom: 0;
        margin-top : 20px;
		font-size: em(20px);

		@media (min-width: $screen-sm) {
			font-size: em(22px);
		}  		
	}

	.article__content {
		display: none;

		@media (min-width: $screen-sm) {
			display: block;
            font-size: em(18px);
		}
	}

	@media (min-width: $screen-sm) {
		flex-direction: row;

		.article__title {
			margin-bottom: 18px;
		}		
		
		.article__thumb {
			flex: 1 0 20%;
			max-width: 20%;
		}

		.article__caption {
			flex: 1 0 80%;
			max-width: 80%;
		}
	}		
}
// ==========================================================================
// Article / Round-Preview
// ==========================================================================

  &--round-preview {
    padding: em($grid-gutter-width/2) 0;
    color: $c-white;
    text-align: center;

    .article__img {
      display: inline-block;
      position: relative;
      width: em(226px);
      height: em(226px);
      padding: em(10px);
      border: 2px dotted $c-white;
      border-radius: 100%;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        top: em(10px);
        bottom: em(10px);
        left: em(10px);
        right: em(10px);
        border-radius: 100%;
        box-shadow: inset 1px 3px 5px transparentize($c-base, .7);
        background-color: transparent;
        transition: all 300ms;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }

      &:hover {
        &:after {
          background-color: transparentize($c-base, .33);
        }

        span {
          top: 50%;
          opacity: 1;
        }
      }

      span {
        position: absolute;
        top: 45%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        opacity: 0;
        font-family: $ff-alt;
        font-size: em(20px);
        font-weight: 600;
        color: $c-white;
        text-transform: uppercase;
        transition: all 300ms;
        z-index: 1;

        &:after {
          content: '+';
          position: absolute;
          bottom: em(-37px, 44px);
          @extend %centerX;
          font-size: em(44px);
        }
      }
    }

    .article__caption {
      padding-top: em(20px);
      line-height: 1.1;
      font-family: $ff-alt;
      font-size: em(20px);
      font-weight: 600;
      color: $c-white;
      text-align: center;
      text-transform: uppercase;
    }

    .article__category {
      position: relative;
      display: block;
      margin-bottom: em(20px, 16px);
      font-size: em(16px, 20px);
      font-weight: 400;
      text-align: center;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: em(-5px);
        @extend %centerX;
        width: em(50px);
        height: 2px;
        background-color: $c-white;
        transition: all 300ms;
      }
    }

    a:hover {
      text-decoration: none;
    }

    @media (min-width: $screen-sm) {
      padding: 0;
    }
  }

}

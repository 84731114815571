@font-face {
    font-family: "villesaintecatherine";
    src: url('/dist/fonts/villesaintecatherine.eot');
    src: url('/dist/fonts/villesaintecatherine.eot?#iefix') format('eot'),
         url('/dist/fonts/villesaintecatherine.ttf') format('truetype'),
         url('/dist/fonts/villesaintecatherine.woff') format('woff'),
         url('/dist/fonts/villesaintecatherine.svg#villesaintecatherine') format('svg');
    font-weight: normal;
    font-style: normal;
}

.fc:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'villesaintecatherine' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fc-camp-jour:before {
  content: "\e949";
  color: #fff;
}
.fc-icon_baseball:before {
  content: "\e939";
}
.fc-icon_bateau:before {
  content: "\e93a";
}
.fc-icon_bicycle:before {
  content: "\e93b";
}
.fc-icon_chien:before {
  content: "\e93c";
}
.fc-icon_fontaine:before {
  content: "\e93d";
}
.fc-icon_hockey:before {
  content: "\e93e";
}
.fc-icon_jeux_eau:before {
  content: "\e93f";
}
.fc-icon_jeux:before {
  content: "\e940";
}
.fc-icon_kayak:before {
  content: "\e941";
}
.fc-icon_parc2:before {
  content: "\e942";
}
.fc-icon_peche:before {
  content: "\e943";
}
.fc-icon_petanque:before {
  content: "\e944";
}
.fc-icon_piscine:before {
  content: "\e945";
}
.fc-icon_sanitaire:before {
  content: "\e946";
}
.fc-icon_skateboard:before {
  content: "\e947";
}
.fc-icon_wifi:before {
  content: "\e948";
}
.fc-icon_trash_can:before {
  content: "\e900";
}
.fc-icon_balloon:before {
  content: "\e901";
}
.fc-icon_carte_interactive:before {
  content: "\e902";
}
.fc-icon_cinema:before {
  content: "\e903";
}
.fc-icon_clinique_medicale:before {
  content: "\e904";
}
.fc-icon_content_tennis:before {
  content: "\e905";
}
.fc-icon_dentiste:before {
  content: "\e906";
}
.fc-icon_business:before {
  content: "\e907";
}
.fc-icon_acces_information:before {
  content: "\e908";
}
.fc-icon_gym:before {
  content: "\e909";
}
.fc-icon_habitations_construction:before {
  content: "\e90a";
}
.fc-icon_info_travaux:before {
  content: "\e90b";
}
.fc-icon_loisirs:before {
  content: "\e90c";
}
.fc-icon_materiaux_secs:before {
  content: "\e90d";
}
.fc-icon_nouveaux_arrivants:before {
  content: "\e90e";
}
.fc-icon_actualites:before {
  content: "\e90f";
}
.fc-icon_recreo-parc:before {
  content: "\e910";
}
.fc-icon_recyclage_collecte:before {
  content: "\e911";
}
.fc-icon_renovations:before {
  content: "\e912";
}
.fc-icon_sante:before {
  content: "\e913";
}
.fc-icon_securite_publique:before {
  content: "\e914";
}
.fc-icon_service_clientele:before {
  content: "\e915";
}
.fc-icon_alertes:before {
  content: "\e916";
}
.fc-icon_animaux:before {
  content: "\e917";
}
.fc-icon_assermentation:before {
  content: "\e918";
}
.fc-icon_library:before {
  content: "\e919";
}
.fc-icon_bureau_poste:before {
  content: "\e91a";
}
.fc-icon_calendrier:before {
  content: "\e91b";
}
.fc-icon_calendrier_evenements:before {
  content: "\e91c";
}
.fc-icon_ecole:before {
  content: "\e91d";
}
.fc-icon_education:before {
  content: "\e91e";
}
.fc-icon_eglise:before {
  content: "\e91f";
}
.fc-icon_egout_aqueduc:before {
  content: "\e920";
}
.fc-icon_emplois:before {
  content: "\e921";
}
.fc-icon_entretien_routes:before {
  content: "\e922";
}
.fc-icon_environnement:before {
  content: "\e923";
}
.fc-icon_famille:before {
  content: "\e924";
}
.fc-icon_galerie_photo:before {
  content: "\e925";
}
.fc-icon_garderie:before {
  content: "\e926";
}
.fc-icon_nuisances_publiques:before {
  content: "\e927";
}
.fc-icon_oeuvres_art:before {
  content: "\e928";
}
.fc-icon_parc:before {
  content: "\e929";
}
.fc-icon_patinoire:before {
  content: "\e92a";
}
.fc-icon_patrimoine:before {
  content: "\e92b";
}
.fc-icon_permis_urbanisme:before {
  content: "\e92c";
}
.fc-icon_police:before {
  content: "\e92d";
}
.fc-icon_pompier:before {
  content: "\e92e";
}
.fc-icon_recherche_adresse:before {
  content: "\e92f";
}
.fc-icon_reclamation:before {
  content: "\e930";
}
.fc-icon_service_police_incendie:before {
  content: "\e931";
}
.fc-icon_sports:before {
  content: "\e932";
}
.fc-icon_taxes_evaluations:before {
  content: "\e933";
}
.fc-icon_transport_commun:before {
  content: "\e934";
}
.fc-icon_vie_communautaire:before {
  content: "\e935";
}
.fc-icon_activity:before {
  content: "\e936";
}
.fc-icon_deneigement:before {
  content: "\e937";
}
.fc-icon_grind:before {
  content: "\e938";
}

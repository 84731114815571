.map {
    margin-bottom: 0;

    &__container {
        position: relative;
        // height: calc( 100vh - 150px );
        height: calc( 100vh - 81px );
        margin-top: 81px;
        overflow: hidden;

        section {
            margin: 0;
        }

        @media (min-width: $screen-sm) {
            height: calc( 100vh - 162px );
            margin-top: 162px;
        }

        @media (min-width: $screen-md) {
            height: calc( 100vh - 126px );
            margin-top: 126px;
        }     
        
        @media (min-width: $screen-lg) {

            height: calc( 100vh - 80px );
            margin-top: 80px;
        }  

        @media (min-width: $screen-xl) {
            height: calc( 100vh - 100px );
            margin-top: 100px;
        }          
    }

    &__form {
        display: none;

        @media (min-width: $screen-sm) {
            display: block;
        }
    }

    &__section {
        list-style-type: none;
        margin-bottom: 0;

        label {
            position: relative;
            margin: 0;
            cursor: pointer;

            .tag {
                display: none;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &.is-active {
            & > li > label {
                .tag {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }
            }
        }

        input[type="checkbox"] {
            position: absolute;
            visibility: hidden;

            & + label {
                .tag:before {
                    content: '';
                    // position: absolute;
                    // top: 5px;
                    // left: 9px;
                    top: -2px;
                    position: relative;
                    width: 7px;
                    height: 12px;
                    border-right: 2px solid $c-white;
                    border-bottom: 2px solid $c-white;
                    transform: rotate(40deg);
                }
            }

            &:checked {
                & + label {
                    .tag {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                    }
                }
            }
        }

        & > li {
            & > label {
                width: 100%;
                padding: em(12px 25px, 20px);
                border-left: 8px solid;
                border-bottom: 1px solid $gray-lighter;
                background-color: $c-white;
                z-index: 2;
                font-size: em(18px);

                .tag {
                    right: em(20px, 20px);
                    color: $c-white;
                    text-align: center;
                    line-height: em(26px, 20px);
                    font-weight: 600;
                }

                @media (min-width: $screen-md) {
                    padding: em(15px 25px, 20px);
                }
            }
        }

        ul {
            display: none;

            label {
                transition: all .1s;
            }

            .fc {
                margin-right: em(15px, 14px);

                &:before {
                    line-height: em(24px, 14px);
                }
            }

            & > li {
                border-bottom: 1px solid $gray-lighter;
                overflow: hidden;

                label {
                    width: 100%;
                    padding: em(12px 30px 10px, 14px);
                    color: $c-white;

                    .tag {
                        right: em(20px, 14px);
                        background-color: $c-white;
                    }
                }

                input[type="checkbox"] + label {
                    .tag:before {
                        border-color: inherit;
                    }
                }
            }

            & > li:last-child {
                label {
                    font-weight: 700;
                }
            }

            label {
                font-size: em(14px);
            }
        }

        &.is-open {
            ul {
                display: block;
            }
        }
    }

    &__section-group,
    &__infobox {
        position: absolute;
        top: 0;
        width: 100%;
        background: $c-white;
        box-shadow: 5px 0 20px 0 rgba(50, 50, 50, 0.4);
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        @media (min-width: $screen-sm) {
            box-shadow: 0 5px 5px 0 rgba(50, 50, 50, 0.4);
        }
    }

    &__section-group {
        left: 0;
        z-index: 9;

        @media (min-width: $screen-sm) {
            bottom: 0;
            min-height: 100%;
            width: 35%;
            z-index: 10;
        }

        @media (min-width: $screen-xl) {
            width: 20%;
        }

        &.is-open {
            height: 100%;
            
            .map__form {
                display: block;
            }

            .map__mobile-trigger:after {
                transform: rotate(180deg);
            }
        }
    }

    &__pin {
        font-size: em(14px, 11px);
        color: $c-white;

        &:before {
            transform: translateX(-50%);
        }
    }

    &__infobox {
        left: 0;
        bottom: 0;
        min-height: 100%;
        padding: em(20px);
        opacity: 0;
        visibility: hidden;
        transition: all .3s;
        z-index: 10;

        &.is-open {
            opacity: 1;
            visibility: visible;
        }

        &__close {
            position: absolute;
            top: em(5px, 34px);
            right: em(20px, 34px);
            font-family: $ff-alt;
            font-size: em(34px);
            font-weight: 300;
            color: $brand-primary;
            cursor: pointer;
        }

        &__title {
            @extend .h2;
            margin: 0;
        }

        &__location {
            margin: em(5px 0 15px, 16px);
            font-family: $ff-alt;
            font-size: em(16px);
            color: $gray-light;
            text-transform: uppercase;
        }

        img {
            max-width: 100%;
            margin-bottom: em(15px);

            &.is-hidden {
                display: none;
            }
        }

        @media (min-width: $screen-sm) {
            width: 300px;
            z-index: 9;

            &.is-open {
                left: 35%;
            }
        }

        @media (min-width: $screen-xl) {
            width: 400px;

            &.is-open {
                left: 20%;
            }
        }
    }

    &__mobile-trigger {
        padding: em(12px 25px 12px 33px, 20px);
        border-bottom: 1px solid $gray-lighter;
        font-family: $ff-alt;
        font-size: em(20px);
        line-height: 1.8;
        text-transform: uppercase;
        cursor: pointer;

        @media (min-width: $screen-sm) {
            display: none;
        }

        &:after {
            @extend %fa;
            @extend .fa-angle-down:before;
            float: right;
            font-size: em(26px, 20px);
            margin-top: em(5px, 26px);
            margin-left: em(15px, 26px);
            line-height: 1;
        }
    }
}

.interactive-maps {
    width: 100%;
    height: 100%;
    position: relative;
}

.section-bar {
    width: 100%;
    height: 10px;
}

.maps-infobox {
    border: 1px solid black;
    margin-top: 8px;
    background: $gray-lighter;
    color: $gray-darker;
    padding: 5px;
}

.template-infobox {
    display: none;
}

.infobox-img {
    width: 50px;
    height: 50px;
}

.markerLabels {
    // margin-left: 0 !important; // overwrite google maps inline styling to align pin icon
    overflow: visible !important; // overwrite google maps inline styling to align pin icon

    span {
        // width: 31px;
        height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;        
    }
}

.map-static {
    align-items: center;
    background: url(../images/bg-map-static.jpg) 50% 50% no-repeat;
    display: flex;
    justify-content: center;
    padding: 20px;

    .card {
        padding: 40px;
        text-align: center;
    }

    .card__title {
        @include rem('font-size', 30px);
        font-weight: 600;
        margin: 0 0 10px;
    }

    p {
        font-family: $ff-alt;
        @include rem('font-size', 16px);
        text-transform: uppercase;
    }
		
    @media screen and (min-width: $screen-md) {
        min-height: 575px;
        text-align: center;

        .card {
            width: 650px;
            padding: 80px;
        }
    }
}

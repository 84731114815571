// ==========================================================================
// Tags
// ==========================================================================

.tag {
	display: inline-block;
	@include rem('width', 25px);
	@include rem('height', 25px);
	border-radius: 100%;
    @include rem('margin-right', 10px);

	&:last-child {
		margin-right: 0;
	}

	&--confirm,
	&--activity {
		background-color: $brand-success;
	}

	&--warning,
	&--work {
		background-color: $brand-warning;
	}

	&--danger,
	&--event {
		background-color: $brand-danger;
	}

	&--city {
		background-color: $brand-info;
	}

	&--important {
		background-color: #fb8026;
	}

	&--community {
		background-color: #3457D4;
	}
}

// ==========================================================================
// Footer
// ==========================================================================
.footer {
    padding: em(40px) em(20px) em(40px);


    @media (min-width: $screen-sm) {
        padding: em(65px) em(20px) em(75px);
    }

    &__search {
        position: relative;
        padding-bottom: em(70px);
        border-bottom: 1px solid $gray-lighter;
        background-color: $c-white;
        z-index: 1;

        .input-group {
            margin: 0 auto;
        }
    }

    &__social {
        position: relative;
        @extend .btn__group;
        padding: em(30px) 0;
        text-align: center;
        background-color: $c-white;
        z-index: 2;

        .btn-icon {
            cursor: pointer;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: em(30px, 17px);
            height: em(30px, 17px);
            font-size: 1.5rem;

            & + .btn-icon  {
                margin-left: 0.625rem;
            }
        }
    }

    &__copyright {
        display: block;
        color: $gray-light;
        text-align: center;
        margin-top: 1.5rem;
    }

    .form--confirmation {
        text-align: center;
    }
}

// ==========================================================================
// Calendar
// ==========================================================================

.calendar {
  &__title {
    margin-bottom: em(5px);
    text-align: center;

    [data-calendar-nav="today"] {
      display: inline-block;
      font-size: 32px;
      font-weight: 300;
      text-transform: uppercase;

      strong {
        font-weight: 500;
      }
    }

    button {
      margin-top: em(15px);
      outline: 0;
      cursor: pointer;

      &[data-calendar-nav="prev"],
      &[data-calendar-nav="next"] {
        vertical-align: middle;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
      }

      &[data-calendar-nav="prev"] {
        margin-right: em(30px);
        border-right: 8px solid;
      }

      &[data-calendar-nav="next"] {
        margin-left: em(30px);
        border-left: 8px solid;
      }
    }
  }

  &__legend {
    margin-bottom: em($grid-gutter-width/2);

    li {
      display: block;
      margin-bottom: em($grid-gutter-width/4, 18px);
      font-family: $ff-alt;
      font-size: em(18px);
      line-height: 30px;
      text-transform: uppercase;
    }

    .tag {
      position: relative;
      margin-right: em($grid-gutter-width/4, 18px);


    }

    @media (min-width: $screen-xxs) {
      text-align: center;

      li {
        display: inline-block;

        &:not(:last-child) {
          //margin-right: em($grid-gutter-width, 18px);
        }
      }
    }
  }
}


// ==========================================================================
// Calendar // Month table
// ==========================================================================


//@media (min-width: $screen-md) {
[class*="cal-cell"] {
  width: percentage(1/7);
}

//}

.cal-row {
  &-fluid {
    @extend %clearfix;
  }

  &-head {
    display: none;
    @extend %back-grad;
  }

  //@media (min-width: $screen-md) {
  &-head {
    display: block;
    margin-bottom: em(15px);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;

    [class*="cal-cell"] {
      float: left;
      padding: em(12px) 0;
      font-family: $ff-alt;
      color: $c-white;
      text-align: center;
      text-transform: uppercase;
    }
  }

  //}
}

.cal-month {
  &-box {
    [class*="cal-cell"] {
      position: relative;
      border-bottom: 1px solid $gray-lighter;
      padding: em(15px);

      &:last-child {
        border-right: 0;
      }
    }
  }

  &-day {
    position: relative;
    min-height: em(80px);

    span {
      font-size: em(28px);
    }

    .events-list {
      margin-top: em(10px);

      a {
        @extend .tag;
        text-decoration: none;
      }

      &--permanent {
        position: absolute;
        top: 5px;
        right: 0;

        .fc {
          font-size: em(26px);
          //color: #c7c7c7;
          text-decoration: none;
          margin-left: em(10px, 26px);
        }
      }
    }
  }

  //@media (min-width: $screen-md) {
  &-box {
    display: table;
    width: 100%;

    .cal-row-fluid {
      display: table-row;

      &:last-child {
        [class*="cal-cell"] {
          border-bottom: 0;
        }
      }
    }

    [class*="cal-cell"] {
      display: table-cell;
      border-right: 1px solid $gray-lighter;
    }
  }

  //}
}

#cal-day-tick {
  display: none !important; // overwrite javascript
}

.cal-row-fluid {
  background: none;
  color: #000;
}

// ==========================================================================
// Calendar // Modals
// ==========================================================================

.calendar {
  &__event {
    .modal-dialog {
      top: 30vh;
    }

    .modal-header {
      padding-bottom: 0;

      .close {
        margin-top: -6px;
      }
    }

    .modal-title {
      font-weight: 600;
      line-height: 1.1;
    }

    .modal-body {
      padding-top: 0;
      font-family: $ff-alt;
    }

    .modal-date {
      margin-bottom: em(20px, 14px);
      font-size: em(14px);
      font-weight: 700;
      text-transform: uppercase;
    }

    .modal-content {
      border: 0;
      border-radius: 0;
      box-shadow: 0 5px 15px rgba(0, 0, 0, .3);
      padding-bottom: em(15px);

      &[class*="tag-"] {
        background-color: $c-white;
      }
    }

    @media (min-width: $screen-sm) {
      .modal-dialog {
        width: 450px;
      }
    }
  }
}

.modal-addcalendar {
  margin-top: em(20px);

  label {
    margin-bottom: em(10px, 12px);
    @extend .fc;
    @extend .fc-icon_calendrier_evenements;
    font-size: em(12px);
    font-weight: 700;
    line-height: em(28px, 12px);
    text-transform: uppercase;
    cursor: pointer;

    &:before {
      margin-right: em(5px, 12px);
      font-size: em(26px, 12px);
    }

    &:hover {
      &:before {
        text-decoration: none;
      }
    }
  }
}

.cal-row-head [class*="cal-cell"] {
  float: left;
  padding: 0.57143em 0;
  font-family: "Josefin Sans", sans-serif;
  color: #4E4E4E;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

#calendar {
  @media (min-width: $screen-md) {
    padding: 30px 30px 20px 30px;
  }
}

.js-month-title,
.js-year-title {
  font-weight: 900 !important;
}

.calendar__title button[data-calendar-nav="prev"],
.calendar__title button[data-calendar-nav="next"] {
  position: absolute;
  margin: 0;
  top: 6px;
}

.calendar__title button[data-calendar-nav="prev"] {
  left: 0;
  @media (min-width: 1200px) {
    left: 50px;
  }
  border-right-color: #2F9DA8;
}

.calendar__title button[data-calendar-nav="next"] {
  right: 0;
  @media (min-width: 1200px) {
    right: 50px;
  }
  border-left-color: #2F9DA8;
}

.calendar__title {
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

.calendar__title [data-calendar-nav="today"] {
  display: inline-block;
  font-size: 1em;
  font-weight: 300;
  text-transform: none;
}

.cal-month-box [class*="cal-cell"] {
  position: relative;
  border-bottom: 1px solid #dddddd;
  padding: 5px;
  text-align: center;
}

.cal-month-day {
  font-size: 1em;
  color: #4E4E4E;
  min-height: 0;

  a {
    margin: 0;
    font-size: 1em;
  }
}

.cal-month-day .events-list--permanent {
  position: relative;
  top: 0;
  right: 0;

}

.calendar__container {
  .fc:before, .btn-icon--map:before, .img--map:before, .alert--danger .alert__content:before, .modal-addcalendar label:before {
    display: none;
  }
}

.cal-month-day .events-list--permanent .fc, .cal-month-day .events-list--permanent .btn-icon--map, .cal-month-day .events-list--permanent .img--map, .cal-month-day .events-list--permanent .img--map:before, .cal-month-day .events-list--permanent .alert--danger .alert__content, .alert--danger .cal-month-day .events-list--permanent .alert__content, .cal-month-day .events-list--permanent .modal-addcalendar label, .modal-addcalendar .cal-month-day .events-list--permanent label {
  font-size: 1em;
  color: #c7c7c7;
  text-decoration: none;
  margin-left: 0;
}

.cal-month-box {
  padding-bottom: 40px;
  border-bottom: 1px solid #DDDDDD;
}

.cal-month-box [class*="cal-cell"] {
  border-bottom: none;
  border-right: none;
}

.cal-month-day .events-list {
  margin-top: 0;
}

.cal-month-dayn {
  line-height: 38px;
}

.tag,
.list--events .list__tag,
.cal-month-day
.events-list a {
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  border-radius: 50%;
  margin-right: 0;
  text-align: center;
}

.cal-month-day {

  span {
    font-size: em(16px) !important;
  }

}

.calendar__legend {
  @extend .row;
  margin-top: 20px;
  padding: 10px 20px;


  .remove-filters-container,
  .js-tag-label {
    display: block;
    width: 100%;
    float: none;
    margin-right: 0;
    margin-bottom: 15px;
    @media (min-width: 400px) and (max-width: 599px) {
      width: 50%;
      float: left;
    }
    @media (min-width: 600px) and (max-width: 768px) {
      width: 33.333%;
      float: left;
    }

  }

  .js-tag-label {
    padding: 0;
    text-align: left;
    line-height: 38px;
    position: relative;
    font-size: em(16px);

    input[type=checkbox] {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: em(38px);
      z-index: 10;
      opacity: 0;
      cursor: pointer;
    }

    .tag {
      margin-right: 10px;
      @media (min-width: 769px) {
        margin-right: 30px;
      }
    }
  }
}

.cal-month-day .event {
    background-color: #D7F1F4 !important;
    color: #2F9DA8 !important;
}

.calendar__legend {
  .tag::before {
    top: 10px !important;
    left: 15px !important;
  }
}

.eventbox {
  background: #fff;
}

.remove-filters-container {
  position: relative;
  margin-top: 20px;
  @media (min-width: 400px) and (max-width: 768px) {
    margin-top: 0;
  }

  .remove-filters {
    position: absolute;
    left: 0;
    white-space: nowrap;
    cursor: pointer;
    color: #2F9DA8;
    font-size: 16px;
  }
}

.text-center {
  text-align: center;
}

.loadMore {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  color: #2F9DA8;
  font-size: 16px;
  border: 1px solid #2F9DA8;
  box-sizing: border-box;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 60px;
  line-height: 60px;
}

// calendar flatpickr
.flatpickr-current-month {
	input.cur-year {
		margin-top: -3px !important; // override datepicker.css 
	}
	
	span.cur-month {
		padding-top: 2px !important; // override datepicker.css
	}
} 

// ==========================================================================
// Typography
// ==========================================================================

html {
	font-size: 16px;
	-ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
}

body {
	font-family: $ff-base;
	font-size: $fs-base-mobile;
	color: $gray-base;
	line-height: $lh-base;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@media (min-width: $screen-sm) {
		font-size: $fs-base;
	}
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6  {
	text-transform: uppercase;
}

h5, .h5 {
	font-weight: 600;
}

a {
	color: inherit;
	text-decoration: none;
}

strong, b {
	font-weight: 600;
}

em, i {
	font-style: italic;
}

ul, ol {
	list-style-type: none;
}

// ==========================================================================
// Mixins
// ==========================================================================


///
/// Change placeholder color
/// --------------------------------------------------
///
/// @example scss - Usage
///   input {
///     @include placeholder { color: red; }
///   }

@mixin placeholder {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";

  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder { @content; }
  }
}


///
/// Rem with pixel fallback
/// --------------------------------------------------
///
/// @example scss - Usage
///   @include rem('padding',10px 15px)
///   @include rem('font-size',10px)

@mixin rem($property, $values) {
  $px : ();
  $rem: ();

  @each $value in $values {
    @if $value == 0 or $value == auto {
      $px : append($px , $value);
      $rem: append($rem, $value);
    }

    @else {
      $unit: unit($value);
      $val: strip-unit($value);
      $base: 16;

      @if $unit == "px" {
        $px : append($px,  $value);
        $rem: append($rem, ($val / $base + rem));
      }

      @if $unit == "rem" {
        $px : append($px,  ($val * $base + px));
        $rem: append($rem, $value);
      }
    }
  }

  @if $px == $rem {
    #{$property}: $px;
  } @else {
    #{$property}: $px;
    #{$property}: $rem;
  }
}


///
/// Provides an easy way to include a clearfix for containing floats.
/// --------------------------------------------------
///
/// @link http://cssmojo.com/latest_new_clearfix_so_far/
///
/// @example scss - Usage
///   .element {
///     @include clearfix;
///   }
///
/// @example css - CSS Output
///   .element::after {
///     clear: both;
///     content: "";
///     display: table;
///   }

@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

@mixin back-grad {
  background-color: #2f9da8;
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMTAwJSIgeDI9IjEwMCUiIHkyPSIwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMmY5ZGE4IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzQwYTk4NSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
	background-image: linear-gradient(45deg, #335356 0, #2f9da8 50%, #2f9da8 50%, #40a985 100%);
	background-size: 200%;
	background-position: 100%;
}

@mixin gradient-animation( $start1, $end1, $start2, $end2, $angle, $transTime ) {
  position: relative;
  background-size: 100%;
  background-image: linear-gradient($angle, $start1, $end1);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start1}', endColorstr='#{$end1}',GradientType=1 );
  z-index: 0;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    display: block;
    height: 100%;
    width: 100%;
    background-image: linear-gradient($angle, $start2, $end2);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start2}', endColorstr='#{$end2}',GradientType=1 );
    border-radius: inherit;
    transition: opacity $transTime;
  }

  &:hover {
     &:after {
       opacity: 1;
     }
  }
}


/// Hides the text in an element, commonly used to show an image. Some elements will need block-level styles applied.
/// --------------------------------------------------
///
/// @link http://zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement
///
/// @example scss - Usage
///   .element {
///     @include hide-text;
///   }
///
/// @example css - CSS Output
///   .element {
///     overflow: hidden;
///     text-indent: 101%;
///     white-space: nowrap;
///   }
///
/// @todo Remove height argument in v5.0.0

@mixin hide-text($height: null) {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;

  @if $height {
    @warn "The `hide-text` mixin has changed and no longer requires a height. The height argument will no longer be accepted in v5.0.0";
  }
}


/* Animate up and down */

@keyframes scroll {
    0% {
        transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        transform: translate(0, 10px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

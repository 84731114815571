// ==========================================================================
// Addresses
// ==========================================================================

.address {
  text-align: center;

  &__title {
    @extend .section__title;
    margin-bottom: em(50px, 36px);
  }

  &__civic {
    padding-bottom: em($grid-gutter-width/2);
    border-bottom: 1px solid $gray-lighter;
  }

  &__postal {
    padding-top: em($grid-gutter-width/2);
  }

  &__label {
    font-size: em(16px);
    margin-bottom: em(10px, 16px);
    text-transform: uppercase;

    & + div {
      line-height: 1.2;
    }
  }

  &__map {
    margin: em(30px) 0 em(40px);
  }

  @media (min-width: $screen-xs) {
    &__civic {
      display: inline-block;
      padding: em(5px $grid-gutter-width 5px 0);
      border-bottom: 0;
      text-align: center;
    }

    &__show-border {
        display: inline-block;
        padding: em(5px $grid-gutter-width 5px 0);
        border-bottom: 0;
        text-align: right;
        border-right: 1px solid $gray-lighter;
    }

    &__postal {
      display: inline-block;
      padding: em(5px 0 5px $grid-gutter-width);
      text-align: left;
    }
  }

  &-ui {
        position: relative;
        width: 280px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 0;
        height: auto;
        background: $c-white;
        text-align: left;
        @media (min-width: $screen-sm) {
            width: 300px;
            top: -20px;
        }


        .ui-front {
          position: absolute;
          z-index: 1000;
          top: 0 !important; /* JQuery UI put values that dont match the wanted layout display */
          left: 0 !important; /* JQuery UI put values that dont match the wanted layout display */
          width: 100% !important; /* JQuery UI put values that dont match the wanted layout display */
          background: $c-white;
          overflow-y: scroll;
          max-height: 250px;
          border-radius: $grid-gutter-width / 4;

        }

        .ui-front li {
            padding: ($grid-gutter-width / 4) $grid-gutter-width;
            transition: all .3s;
            line-height: 1.2;
            color: $c-base;
            font-size: 1rem;
            cursor: pointer;
            @media (min-width: $screen-md) {
                &:hover {
                    color: $c-white;
                    background-color: $brand-primary;
                }
            }
        }
    }
}

// ==========================================================================
// Utilities
// ==========================================================================

@import "functions";
@import "helpers";
@import "mixins";

.show-grid {
	margin-bottom: 15px;
	[class^=col-] {
	    padding-top: 10px;
	    padding-bottom: 10px;
	    background-color: #eee;
	    border: 1px solid #ddd;
	}
}
.color-swatches {
	overflow:hidden;
	.color-swatch {
		float: left;
		width: 60px;
		height: 60px;
		margin: 0 5px 10px;
		border-radius:$border-radius-base;
		@media (min-width:$screen-sm){
			width: 90px;
			height: 90px
		}
	}
	.bg-gray-darker 	{ background-color: $gray-darker }
	.bg-gray-dark		{ background-color: $gray-dark }
	.bg-gray			{ background-color: $gray }
	.bg-gray-light		{ background-color: $gray-light }
	.bg-gray-lighter	{ background-color: $gray-lighter }

	.bg-brand-primary	{ background-color: $brand-primary }
	.bg-brand-secondary	{ background-color: $brand-secondary }
	.bg-brand-success	{ background-color: $brand-success }
	.bg-brand-warning	{ background-color: $brand-warning }
	.bg-brand-danger	{ background-color: $brand-danger }
	.bg-brand-info		{ background-color: $brand-info }
}

.has-overlay {
	z-index: 2;
	position: relative;
	@media (min-width: $screen-md) {
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba($gray-base, .45);
			z-index: 6;
		}             
	}
}

.page__title--banner {
	&.has-overlay {
		@media (min-width: $screen-md) {
			&::before {
				z-index: -1;
			}
		}
	}
}
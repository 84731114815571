.counselor {
    &--list {
        @extend %clearfix;
        @extend .col-xs-6;
        @extend .col-sm-4;
        @extend .col-md-3;
        margin-bottom: em($grid-gutter-width);
    }

    &--entry {
        @extend .row;

        .counselor__image {
            @extend .col-xs-12;
            @extend .col-sm-4;
            @extend .col-md-3;
        }

        .counselor__content {
            @extend .col-xs-12;
            @extend .col-sm-8;
            @extend .col-md-9;
        }

        .counselor__title {
            font-family: $ff-alt;
            font-size: em(28px);
            color: $brand-primary;
            text-transform: uppercase;
            text-align: center;
        }

        .btn--primary:not(.btn-icon) {
            display: block;
            margin: 0 auto;
        }

        .btn__group {
            margin: em($grid-gutter-width/2) 0;
            text-align: center;
        }

        .btn-icon {
            margin-top: em($grid-gutter-width/4);
        }
    }

    &__image {
        position: relative;
        margin-bottom: em($grid-gutter-width/2);

        img {
            max-width: 100%;
        }
    }

    &__content {

    }

    &__name {
        font-size: em(28px);
        color: $brand-primary;
    }

    &__title {

    }

    @media (min-width:$screen-sm) {
        &--entry {
            .counselor__title {
                text-align: left;
            }

            .btn--primary:not(.btn-icon) {
                display: inline-block;
            }

            .btn__group {
                text-align: left;
            }

            .btn-icon {
                margin-top: 0;
            }
        }

        &__image {
            img {
                width: 100%;
            }
        }
    }
}

$screen-xm :     $screen-xs !default;
$screen-xm-min:  $screen-xm !default;
$screen-xm-max:  ($screen-sm-min - 1) !default;
$container-xm:   $screen-xs !default;


// Generate grid

@media (min-width: $screen-xm-min) {
  @include make-grid(xm);
}


// Generate columns

@mixin make-xm-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-xm-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}

@mixin make-xm-column-offset($columns) {
  @media (min-width: $screen-xm-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}

@mixin make-xm-column-push($columns) {
  @media (min-width: $screen-xm-min) {
    left: percentage(($columns / $grid-columns));
  }
}

@mixin make-xm-column-pull($columns) {
  @media (min-width: $screen-xs-min) {
    right: percentage(($columns / $grid-columns));
  }
}

@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-xm-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xl-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-xm-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xl-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
  }
}


// Generate visibility class helpers

@include responsive-invisibility('.visible-xm');

.visible-xm-block,
.visible-xm-inline,
.visible-xm-inline-block {
  display: none !important;
}

.visible-xs-block {
  @media (max-width: ($screen-xm-min - 1)) {
    display: block !important;
  }
}

.visible-xs-inline {
  @media (max-width: ($screen-xm-min - 1)) {
    display: inline !important;
  }
}

.visible-xs-inline-block {
  @media (max-width: ($screen-xm-min - 1)) {
    display: inline-block !important;
  }
}

@media (min-width: $screen-xm-min) {
  @include responsive-visibility('.visible-xm');
}

.visible-xm-block {
  @media (min-width: $screen-xm-min) {
    display: block !important;
  }
}

.visible-xm-inline {
  @media (min-width: $screen-xm-min) {
    display: inline !important;
  }
}

.visible-xm-inline-block {
  @media (min-width: $screen-xm-min) {
    display: inline-block !important;
  }
}

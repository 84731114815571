.hero {
    position: relative;
    overflow: hidden;
    background: rgba(black, .9);
    &::after {
        content: '';
        min-height: inherit;
        font-size: 0;
    }

    .ytplayer-container {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }

    @media (min-width: $screen-sm) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-height: 100%;
    }

}

.hero__cover {

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
        opacity: .2;
        z-index: 1;
    }

    @media (min-width: $screen-md) {
        position: absolute;
    }
}


.hero__cover-image {
    width: 100%;
    height: 100%;

    @media (min-width: $screen-md) {
        display: none; // Hide for now
    }

    img {
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
    }

}

.hero__cover-video {
    display: none;

    @media (min-width: $screen-md) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity 200ms ease;
        display: block;
    }
}

.hero__cover-video {
    &::after {
        content: "";
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../images/bg-color.jpg);
        background-color: $brand-primary;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 10;
        transition: opacity 400ms ease;
        opacity: 1;
        will-change: opacity;
    }

    @media (min-width: $screen-md) {
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 50px;
            height: 50px;
            transform: translate(-50%, -50%);
            background-image: url(/dist/images/svg/ic-loading.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 50px auto;
            z-index: 9999;
            transition: opacity 400ms ease;
            opacity: 1;
            will-change: opacity;
        }
    }

    &.loaded {
        &::before,
        &::after {
            opacity: 0;
        }
    }
}






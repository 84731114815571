// ==========================================================================
// Header
// ==========================================================================

header {
    transition: transform 0.3s;

    .header-content {
        position: relative;
        width: 100%;
        padding: 100px $grid-gutter-width/2;
        text-align: center;
    }

    & > a.btn-icon {
        position: absolute;
        display: none;
        @extend %centerX;
        line-height: em(66px, 34px);
        z-index: 2;
        @media (min-width:$screen-md) {
            bottom: $grid-gutter-width;
            display: block;
        }

    }

    .form-helpbar {
        padding: 2em 0;
        z-index: 9999;

        .btn {
            z-index: 9999;
        }

        .input-group {
            > .btn.btn--primary {
                @media (min-width:$screen-sm) {
                    margin-left: 20px;
                }
                @media (min-width:$screen-md) {
                    margin-left: 10px;
                }
            }
        }
    }

    @media (min-width:$screen-md) {
        .form-helpbar {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            padding: 0;
            transform: translateY(-50%);
        }
    }

    @media (min-width:$screen-md) {
        .header-content {
            position: absolute;
            top: 50%;
            padding: 0 $grid-gutter-width;
            transform: translateY(-50%);
        }

        .form-helpbar {
            top: auto;
            bottom: 38px;
            transform: none;
        }
    }

    @media (min-width:$screen-lg) {
        .form-helpbar {
            bottom: 58px;
        }
    }
}

// ==========================================================================
// Header / Home
// ==========================================================================

.header {
    &--home {
        @extend %img-cover;
        position: relative;
        width: 100%;
        text-align: center;
        color: $c-white;
        background-position: top center;
        background-size: cover;
        @media (min-width:$screen-md) {
            height: 100vh;
        }

        section {
            margin: 0;
        }

        @media (min-width:64.0625em) {
            background-attachment: fixed;
        } // 1025px to disable it for all mobile devices including ipad lanscape

        img {
            position: relative;
            z-index: 1000;
        }

        .today {
            z-index: 9999;
        }

        @media (max-width: $screen-md) {
            .form-helpbar__title {
                color: $c-white;
                text-shadow: none;
            }
        }

        @media (max-width: $screen-sm-min) {
            .form-helpbar__title {
                color: $brand-primary
            }
        }        
    }
}

header.header--home {
    background-image: none !important; 
    
    @media (min-width:$screen-md) {
        height: 88vh;
    }

    &::before {
        background: linear-gradient(to bottom, rgba(40,40,40,0.75) 0%, rgba(0,0,0,0) 100%);
        z-index: 9999;
    }

    iframe {
        transform: translateZ(0);
    }

    .input-group {
        margin-bottom: 0;
    }

}

header.header--home {
    .hero {
        display: none;

        @media (min-width:$screen-sm) {
            display:block;
        }
    }
}
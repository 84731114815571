li.calendar-filter-actions {
	display: flex;
	padding: 0;
	height: 50px;
	width: 100%;

	& > div {
		flex: 1 0 50%;
		max-width: 50%;
		display: flex;
		align-items: center;
		height: 100%;
		position: relative;

        &:first-child {
            border-right: rgba(255, 255, 255, .2) 1px solid;
        }
	}

	span {
		line-height: 1.3;
		text-align: center;
		padding: 1em;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 1em;
		width: 100%;
        height: 100%;
        font-size: .8rem;
		background-color: $brand-primary;
		color: white;	
        transition: background-color 400ms ease, color 400ms ease, border-color 400ms ease;
        border: $brand-primary 1px solid;
	}
}

.calendar__legend .js-tag-label input[type=checkbox] {
    margin-top: 0;
}

.js-tag-label {
    .tag.is-active + .js-tag-label__text {
        color: $brand-primary;
    }
}

[calendar-filter-tag-remove] {
	position: relative;
	z-index: 10;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	display: none;

	&.is-active {
		display: flex;
	}
}

[calendar-alltag-trigger],
[calendar-submit-trigger],
[calendar-reset-trigger] {
    cursor: pointer;
    text-transform: none;

    @media(min-width: $screen-lg) {
        &:hover {
            span {
                background-color: white;
                color: $brand-primary;
                border-color: $brand-primary;
            }
        }
    }
}
// ==================================================
// Icon Component
// ==================================================

$c-icon            : #f0f0f0;
$t-icon            : .3s;
$s-icon            : 26px;
$s-icon-bar        : 3px;

.icon {
  position: relative;
  cursor: pointer;

  &__group {
    @include rem('font-size', 16px); // change this value to control icon size
  }

  &:before,
  &:after {
    content: '';
  }

  span,
  &:before,
  &:after {
    position: absolute;
    display: block;
    background-color: $c-icon;
    transition: all .2s;
  }

// ========================================
// Icon Component / Hamburger
// ========================================

  &--hamburger,
  &--hamburger-cross {
    width: 1em * 1.5;
    height: 1em;

    span,
    &:before,
    &:after {
      left: 0;
      transform: translate3D(0, 0, 0);
      width: 100%;
      height: $s-icon-bar;
    }

    &:before {
      top: 0;
    }

    span {
      top: 50%;
      transform: translate3D(0, -50%, 0);
    }

    &:after {
      bottom: 0;
    }
  }

  &--hamburger {
    &.is-active {
      &:before {
        top: 50%;
        transform: translate3D(0, -50%, 0);
      }

      &:after {
        bottom: 50%;
        transform: translate3D(0, 50%, 0);
      }
    }
  }

  &--hamburger-cross {
    &:before,
    &:after {
      transform-origin: center;
    }

    &.is-active {
      &:before {
        top: 50%;
        transform: translate3D(0, -50%, 0) rotate(45deg)
      }

      span {
        opacity: 0;
      }

      &:after {
        bottom: 50%;
        transform: translate3D(0, 50%, 0) rotate(-45deg);
      }
    }
  }

// ========================================
// Icon Component / Plus
// ========================================

  &--plus,
  &--plus-cross {
    width: 1em;
    height: 1em;

    &:before {
      top: 50%;
      left: 0;
      transform: translate3D(0, -50%, 0) rotate(0);
      width: 100%;
      height: em($s-icon-bar);
    }

    &:after {
      top: 50%;
      left: 50%;
      transform: translate3D(-50%, -50%, 0) rotate(0);
      width: em($s-icon-bar);
      height: 100%;
    }
  }

  &--plus {
    &.is-active {
      &:before {
        transform: translate3D(0, -50%, 0) rotate(180deg);
      }

      &:after {
        transform: translate3D(-50%, -50%, 0) rotate(90deg);
      }
    }
  }

  &--plus-cross {
    &:before,
    &:after {
      transform-origin: center;
    }

    &.is-active {
      &:before {
        transform: translate3D(0, -50%, 0) rotate(135deg);
      }

      &:after {
        transform: translate3D(-50%, -50%, 0) rotate(135deg);
      }
    }
  }

// ========================================
// Icon Component / Arrow
// ========================================

  &--arrow,
  &--arrow-cross {
    width: 1em;
    height: 1em;

    &:before,
    &:after {
      top: 50%;
      width: 80%;
      height: em($s-icon-bar);
    }

    &:before {
      left: 10%;
      transform: translate3D(-30%, -50%, 0) rotate(45deg);
    }

    &:after {
      right: 10%;
      transform: translate3D(30%, -50%, 0) rotate(-45deg);
    }
  }

  &--arrow {
    &:before,
    &:after {
      transition: all .15s;
    }

    &.is-active {
      &:before {
        width: 80%;
        transform: translate3D(-30%, -50%, 0) rotate(-45deg);
      }

      &:after {
        width: 80%;
        transform: translate3D(30%, -50%, 0) rotate(45deg);
      }
    }
  }

  &--arrow-cross {
    &.is-active {
      &:before {
        width: 100%;
        transform: translate3D(-10%, -50%, 0) rotate(45deg);
      }

      &:after {
        width: 100%;
        transform: translate3D(10%, -50%, 0) rotate(-45deg);
      }
    }
  }

// ========================================
// Icon Component / Search
// ========================================

  &--search {
    width: 1em;
    height: 1em;
    transition: transform .2s;

    &:before,
    &:after {
      bottom: 0;
      right: .2em;
      width: .5em;
      height: em($s-icon-bar);
      transform: rotate(45deg);
      transform-origin: right bottom;
    }

    span {
      display: block;
      top: 0;
      left: 0;
      width: .5em;
      height: .5em;
      border: em($s-icon-bar) solid $c-icon;
      border-radius: 100%;
      background-color: transparent;
      transform: scale(1);
    }

    &.is-active {
      transform: rotate(90deg);

      &:before,
      &:after {
        bottom: 50%;
        right: 0;
        width: 100%;
        transform-origin: center;
      }

      &:before {
        transform: translate3D(0, 50%, 0) rotate(45deg);
      }

      &:after {
        transform: translate3D(0, 50%, 0) rotate(135deg);
      }

      span {
        transform: scale(0);
      }
    }
  }

// ==================================================
// Icon Component / Cross
// ==================================================

    &--cross {
      width: 1em;
      height: 1em;

      &:before,
      &:after {
        top: 50%;
        transform-origin: center;
        width: 100%;
        height: em($s-icon-bar);
      }

      &:before {
        transform: translateY(-50%) rotate(45deg);
      }

      &:after {
        transform: translateY(-50%) rotate(-45deg);
      }
    }
}

// ==================================================
// Special icon / Page Camp de Jour
// ==================================================

.fc-icon_submenupage {
    &::before {
        content: "";
        background:url(../images/svg/ic-camp_jour.svg);
        background-size: 60px auto;
        width: 60px;
        height: 60px;
    }
}

.fc-icon_course {
    &::before {
        content: "";
        background:url(../images/svg/ic-cross-country.svg);
        background-size: 45px auto;
        width: 45px;
        height: 45px;
    }
}

// ==========================================================================
// Submenus
// ==========================================================================

.submenu {
//   background-color: $c-white;
//   text-align: center;

//   a {
// 	cursor: pointer;
// 	text-decoration: none;
// 	font-size: 1rem;
//   }

//   a, span {
//     position: relative;
//     display: block;
//     margin-top: em(10px);
//     padding: em(16px) 0;
//     transition: background-color .3s;
//   }

//   @media (min-width: $screen-sm) {

//     .container {
//       display: table;
//       min-height: em(70px);
//     }          

//     a, span {
//       padding-left: em(10px);
//       padding-right: em(10px);
// 	  background-color: transparent;
// 	  text-transform: uppercase;

//       &:hover {
//           color: inherit;
//           text-decoration: none;
//           background-color: darken($c-white, 10%);
//       }
//     }
//   }

//   @media (min-width: $screen-md) {
//     font-size: em(18px);
//   }

//   @media (min-width: $screen-lg) {
//     font-size: em(21px);
//   }
}



// .submenu {
// 	@media (max-width: 1024px) {
// 		padding: 1rem 0;

// 		a {
// 			display: block;
// 			color: $gray-dark;
// 			padding: 1rem 0;

// 			&:hover {
// 				color: $gray-dark;
// 				background-color: transparent;
// 			}
// 		}
// 	}
// }



.submenu {
	background-color: white;

	.container {
		display: flex;
		flex-direction: column;
		padding: 0;
		width: 100%;

		@media (min-width: $screen-sm) {
			flex-direction: row;
		}

		@media (min-width: $screen-lg) {
			max-width: $container-xlarge-desktop;
		}
	}
}

.submenu--desktop {
	display: none;

	@media (min-width: $screen-sm) {
		display: block;
	}	

	.container {
		display: flex;
		justify-content: center;
	}
}

.submenu--mobile {
	display: flex;

	.container {
		flex: 1 0 auto;
	}

	@media (min-width: $screen-sm) {
		display: none;
	}	
}

.submenu__item {
	cursor: pointer;
	display: block;
	text-decoration: none;
	padding: 1.5rem 1rem;
	transition: background-color 400ms ease;
	font-size: 1rem;
	display: flex;
	align-items: center;
	flex-grow: 1;

	@media (min-width: $screen-sm) {
		text-transform: uppercase;
		justify-content: center;
	}

	@media (min-width: $screen-sm) {
		flex-grow: 0;
	}	

	@media (min-width: $screen-xl) {

		font-size: em(21px);

		&:hover,
		&:visited {
			color: $gray-dark;
			background-color: darken($c-white, 10%);
			text-decoration: none;
		}
	}	

	&:focus,
	&:visited,
	&:active {
		text-decoration: none !important;
		outline: 0;
	}			
}

.submenu__item--active {
	text-decoration: none;
	display: flex;
	justify-content: space-between;

	@media (min-width: $screen-sm) {
		background-color: darken($c-white, 10%);
		font-weight: 600;
		justify-content: center;
	}

	.fa {
		font-size: 1rem;
		color: $brand-primary;

		@media (min-width: $screen-sm) {
			display: none;
		}		
	}
}

.submenu__item-dropdown {
	display: none;
	position: absolute;
	top: 0;
	background-color: white;
	width: 100%;
	top: 81px; // Menu mobile height
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0), 0px 10px 20px rgba(0, 0, 0, 0.05);
}

[gr-submenu].is-active {
	.submenu__item--active {
		.fa {
			transform: rotate(180deg);
		}
	}

	.submenu__item-dropdown {
		display: block;
	}
}

[gr-submenu] {
	position: fixed;
	width: 100%;
	z-index: 1031;
	transition: transform 100ms linear;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.05);
}


.gr-submenu-scroll-active {
	.main {
		transform: none !important;
	}
}

.has-mobilenav-opened {
	[gr-submenu] {
		z-index: 1000;
	}

	[gr-submenu-trigger] {
		pointer-events: none;
	}
}






.calendar__legend--mobile {
	display: block;
	margin: 0;
	padding: 0;
	width: 100%;

	@media(min-width: $screen-sm-min) {
		display: none;
	}

	.calendar-filter-actions {
		padding: 0;
	}

	li,
	li.js-tag-label,
	li.remove-filters-container {
		width: 100%;
		padding: .4em 1em;
		border-bottom: 1px solid #dddddd;
		background-color: #fff;
		z-index: 2;
		font-size: 14px;
		display: flex;
		align-items: center;
	}

	li.js-tag-label {
		margin-bottom: 0;

		&:first-of-type {
			border-top: 1px solid #dddddd;
		}

		.tag {
			width: 30px;
			height: 30px;
			line-height: 30px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			margin-right: 15px;

			img {
				width: 100%;
				display: block;
				max-height: 15px;
			}
		}

		.tag--conferences {
			img {
				max-height: 20px;
			}
		}

		.tag--events_aventures {
			img {
				max-height: 20px;
			}
		}

		.tag--tlmdehors {
			img {
				max-height: 25px;
			}
		}

		input[type=checkbox] {
			height: 100%;
			margin-top: 0;
		}
	}

	& > li:first-child {
		justify-content: space-between;
		border-bottom: 0;

        &:after {
            @extend %fa;
            @extend .fa-chevron-down:before;
			line-height: 1;
			font-size: 1rem;
    		color: #2f9da8;
        }
	}

	.remove-filters-container {
		margin: 0;
		line-height: 38px;

		.remove-filters {
			position: relative;
		}
	}

	.filters-wrapper {
		display: none;

		&.is-active {
			display: block;
			position: absolute;
			top: 81px;
			left: 0;
			width: 100%;
		}
	}

	[calendar-filter-trigger] {
		cursor: pointer;
		margin-bottom: 0;

		button {
			line-height: 1;
		}
	}
}

.filter-form-submenu {
	left: 0;
	width: 100%;
	background-color: white;
	display: flex;
	align-items: center;

	&.is-active {
		.calendar__legend--mobile > li:after {
			transform: rotate(180deg);	
		}
	}
}
// ==========================================================================
// Books
// ==========================================================================

.book {
  position: relative;
  font-family: $ff-alt;

  &__group {
    @extend %clearfix;
    margin-bottom: em(25px);
    transition: transform 1.2s;

    .book {
      display: inline-block;
      padding: 0 em(20px);

      &:focus,
      a:focus {
        outline: 0;
      }

      img {
        opacity: 0;
      }
    }

    .book__cover,
    .book__title,
    .book__author {
      @include rem('font-size', 16px);
      white-space: normal;
    }

    .book__cover {
      max-width: 100%;
      height: auto;
    }
  }

  &__carousel {
    white-space: nowrap;
    overflow: hidden;
  }

  &--withlink {
      &:hover {
        .book__cover {
          &:after {
            background-color: transparentize($c-base, .5);
          }

          span {
            top: 50%;
            opacity: 1;
          }
        }

        .book__title,
        .book__author {
          color: $brand-primary;
        }
      }
  }

  &__cover {
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: transparent;
      transition: all 300ms;
    }

    span {
      position: absolute;
      top: 45%;
      left: 50%;
      width: 100%;
      font-size: em(16px);
      font-weight: bold;
      color: $c-white;
      text-align: center;
      text-transform: uppercase;
      opacity: 0;
      transform: translate(-50%, -50%);
      transition: all .3s;
      z-index: 1;

      &:after {
        content: '+';
        position: absolute;
        bottom: em(-40px, 44px);
        @extend %centerX;
        font-size: em(44px, 16px);
      }
    }
  }

  &__title {
    margin-top: em(10px);
    text-transform: uppercase;
    transition: color .3s;
  }

  &__author {
    font-size: em(16px);
    transition: color .3s;
  }

  @media (min-width: $screen-xs) {
    &__group {
      .book__cover,
      .book__title{
        @include rem('font-size', 21px);
      }
    }
  }
}

.slick-initialized {
  &.book__group {
    .book {
      img {
        opacity: 1;
      }
    }
  }
}

$calendartags: (
        fc : #444444,
        tag--activity : #63cc7a,
        tag--event : #d43459,
        tag--city : #003087,
        tag--important : #fb8026,
        tag--work : #996633,
        tag--community : #722f6b,
        tag--events_aventures : $brand-warning,
        tag--tlmdehors : #99cc33,
        tag--conferences: #9900cc,
        tag--events_adultes: #1d3666,
        tag--events_jeunesse: #3dbb95,
);
@each $tag, $color in $calendartags {
  .calendar__event .modal-content.#{$tag} {
    .modal-title,
    .modal-addcalendar label:before {
      color: $color;
    }
  }
  .calendar__sidebar .tag.#{$tag},
  .list--events li .date.#{$tag} {
    background: $color;
  }
  .calendar__sidebar .tag.#{$tag}.is-active {
    box-shadow: 0px 0px 0px 6px rgba($color, .5);
  }
  .calendar__event .modal-content.#{$tag} {
    .modal-title,
    .modal-addcalendar label:before {
      color: $color;
    }
  }
}

// ==========================================================================
// Images
// ==========================================================================

section.img {
  margin-bottom: 9rem;
}

.img {
    position: relative;
    margin-bottom: .75rem;

  img {
    max-width: 100%;
  }

  // ==========================================================================
  // Images / Interative Map Icon
  // ==========================================================================

  &--map {
    text-align: center;
    padding-bottom: 4.5rem;
    @extend .fc;
    @extend .fc-icon_carte_interactive;

    &:before {
      @extend .btn-icon;
      @include rem('font-size', 26px);
      width: em(60px, 26px);
      height: em(60px, 26px);
      @extend .fc;
      @extend .fc-icon_carte_interactive;
      box-shadow: none;
      position: absolute;
      bottom: 4.5rem;
      left: 50%;
      padding: em(6px, 26px);
      transform: translate(-50%, 50%);
      line-height: em(60px, 26px);
      box-sizing: content-box;
      background-clip: content-box;
      border: 2px dotted $c-white;
    }

    &:after {
      content: 'Voir sur la carte interactive';
      position: absolute;
      bottom: .75rem;
	  @extend %centerX;
      @include rem('font-size', 14px);
      text-transform: uppercase;
			white-space: nowrap;
    }
  }

  &--modal {
      width: em(150px);
  }
}

.img--banner {
    & > img {
        width: 100%;
    }
}

// ==========================================================================
// Images / Alignment
// ==========================================================================

@media (min-width: $screen-sm) {
  .img {
    &--left {
      float: left;
      max-width: 60%;
      margin-right: em($grid-gutter-width);
      clear: both;
    }

    &--right {
      float: right;
      max-width: 60%;
      margin-left: em($grid-gutter-width);
      clear: both;
    }
  }
}

.img {
    &--center {
        text-align: center;
    }
}

// ==========================================================================
// Opening Hours
// ==========================================================================

.opening-hours {
  padding-top: em(18px);

  &__title {
    @extend .h5;
    text-align: center;
  }

  &__link {
    @extend .link--default;
    display: block;
    text-align: center;
    margin: 1rem auto;
  }

  &__day {
    padding: em($grid-gutter-width/4) em($grid-gutter-width/2);
    border-bottom: 1px solid $gray-lighter;
    text-align: center;

    span {
      display: block;
      text-transform: uppercase;

      &:not(.opening-hours__hours) {
        font-family: $ff-alt;
        font-size: em(18px);
        font-weight: 400;
        color: $gray-light;
        transition: color .3s;
      }

      &.opening-hours__hours {
        font-size: em(16px);
        font-weight: 600;
        transition: color .3s;
      }
    }

    &:hover {
      span {
        &:not(.opening-hours__hours),
        &.opening-hours__hours {
          color: $brand-primary;
        }
      }
    }
  }

  @media (min-width: $screen-sm) and (max-width: $screen-md) {
    &__day {
      padding: em($grid-gutter-width/4);
    }
  }

  @media (min-width: $screen-sm) {
    display: table;
    width: 100%;

    &__day {
      display: table-cell;
      border-bottom: 0;
      border-right: 1px solid $gray-lighter;
      text-align: left;

      &:last-child {
        border-right: 0;
      }
    }
  }
}

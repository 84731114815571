// ==========================================================================
// Functions
// ==========================================================================

/// Strips the unit from a number.
/// --------------------------------------------------
///
/// @param {Number (With Unit)} $value
///
/// @example scss - Usage
///   $dimension: strip-units(10em);
///
/// @example css - CSS Output
///   $dimension: 10;
///
/// @return {Number (Unitless)}

@function strip-unit($val) {
  @return ($val / ($val * 0 + 1));
}


///
/// Convert pixels to ems
/// --------------------------------------------------
///
/// eg. for a relational value of 12px write em(12px) when the parent is 16px
/// if the parent is another value say 24px write em(12px, 24px)
///
/// @example scss - Usage
///   font-size: em(12px);
///   font-size: em(12px, 24px);

@function em($values, $context: $fs-base) {
  $em: ();

  @each $value in $values {
    @if $value == 0 or $value == auto {
      $em: append($em, $value);
    }
    @else {
      $em: append($em, ( ( $value/ $context ) * 1em ));
    }
  }

  @return $em;
}

// ==========================================================================
// Forms / Helpbar
// ==========================================================================


.form-helpbar {
	@extend .container-fluid;
	text-align: center;

	&__title {
		@extend .article__title;
		margin-top: 0;
        font-size: 24px;
		
        @media (min-width:$screen-xs) {
			font-size: 30px;
		}

		color: white;
		text-shadow: 2px 2px 2px #000;
	}

	&__col {
		position: relative;
		display: block;
		padding-left: $grid-gutter-width/2;
		padding-right: $grid-gutter-width/2;
		margin: 0 auto $grid-gutter-width/2;

        button {
            color: $color-font-form;
			cursor: pointer;
			outline: 0;
            font-size: 20px;
            white-space: normal;
            text-transform: none;

            &:not(.dropdown-toggle) {
                line-height: 1;

                &:hover {
                    color: $color-font-form;
                    border-color: #bebebe;
                }
            }

            &[type="submit"] {
    			position: absolute;
    			top: 50%;
    			transform: translateY(-50%);
    			right: em(20px);
    		}
        }

		@media (min-width:$screen-xs) {
			display: inline-block;
			margin: 0 1% $grid-gutter-width/2;
			width: 30%;
		}

		@media (min-width:$screen-sm) {
			margin: 0 $grid-gutter-width/4 $grid-gutter-width/2;
		}

		@media (min-width:$screen-xl) {
			width: 27%;
		}
	}

	.form-control {
        border-radius: $border-radius-large;
    }

 	.dropdown-menu {
        border-bottom-left-radius: $border-radius-large;
        border-bottom-right-radius: $border-radius-large;
    }

    header & {
    	background: transparent;
	}
	
	.input-group {
		max-width: 100%;
		display: block;
		padding-left: $grid-gutter-width / 2;
		padding-right: $grid-gutter-width / 2;
		margin-top: 10px;
		@media (min-width:$screen-sm) {
			display: flex;
			justify-content: center;
			margin-bottom: 0;
		}

		.fa {
			vertical-align: -1px;
			line-height: 0; // Fix iOS bug height
			&.fa-close {
				width: 20px;
			}
		}

		.form-helpbar__search {
			position: relative;
			input {
				border-top-left-radius: 30px;
				border-bottom-left-radius: 30px;
				z-index: 3;
			}
		}
	}

	.form-control:first-child {
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		width: calc(100% - 80px);

		@media (min-width:$screen-sm) {
			width: auto;
		}
	}

	.input-group-btn {
		width: auto;
		vertical-align: top;
		z-index: 3;

		.btn {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
		}
	}

	.input-group > .btn.btn--primary {
		vertical-align: bottom;
		margin-left: $grid-gutter-width / 4;
		margin-top: $grid-gutter-width / 4;

		@media (min-width:$screen-sm) {
			margin-top: 0;
		}
	}
}

.form__row {
    margin-top: $grid-gutter-width;
}

.helpbar {
	margin-top: 8rem;
}

.autocomplete {
	display: none;
	position: absolute;
	bottom: -10px; // Button bottom padding to compensate
	left: 0;
	width: 100%;
	transform: translateY(100%);
	padding: $grid-gutter-width / 2;
	background-color: white;
	border: $brand-primary 1px solid;
	text-align: left;
	border-bottom-right-radius: 30px;
	border-bottom-left-radius: 30px;
	z-index: 2;

	li {
		a {
			color: $brand-primary;
			font-size: .8rem;
			cursor: pointer;

			span {
				display: block;
			}

			&:hover {
				text-decoration: none;
				color: $gray-base;
			}
		}
		&:not(:last-child) {
			margin-bottom: $grid-gutter-width / 3;
		}
	}

	&__item--results {
		font-weight: bold;
	}

	@media (max-width: 768px) {
		transform: translateY(100%);
		bottom: 30px;
	}

	@media (max-width: 767px) {
		transform: translateY(calc(100% + 45px)); // Compensate the button height + the padding of the search box
		bottom: 0;
		padding-top: 50px;
	}
}

// ==========================================================================
// Forms / Register
// ==========================================================================

.form-register {
	margin: 0 auto;
	text-align: left;
	padding-top: $grid-gutter-width;
	padding-bottom: $grid-gutter-width;

	.label {
		display: block;
		text-align: left;
		margin-bottom: em(4px, 18px);
		padding-left: 0;
		font-size: em(18px);
		color: $gray-base;
		font-weight: 500;

		&--system{
			&:before {
				content: '';
				background: url('../images/icons/uEA0A-icon_grind.svg') center center;
				background-repeat: no-repeat;
				font-family: $ff-glyph;
				font-size: $font-size-base + 5; //25px
				color: $brand-primary;
				padding: $padding-base-horizontal;
			}
		}

		&--alert{
			&:before {
				content: '';
				background: url('../images/icons/uEA03-icon_alertes_2.svg') center center;
				background-repeat: no-repeat;
				font-family: $ff-glyph;
				font-size: $font-size-base + 5; //25px
				color: $brand-primary;
				padding: $padding-base-horizontal;
			}
		}

		&--event{
			&:before {
				content: '';
				background: url('../images/icons/uEA09-icon_calendrier_evenements.svg') center center;
				background-repeat: no-repeat;
				font-family: $ff-glyph;
				font-size: $font-size-base + 5; //25px
				color: $brand-primary;
				padding: $padding-base-horizontal;
			}
		}

		&--activity{
			&:before {
				content: '';
				background: url('../images/icons/uEA0A-icon_activity.svg') center center;
				background-repeat: no-repeat;
				font-family: $ff-glyph;
				font-size: $font-size-base + 5; //25px
				color: $brand-primary;
				padding: $padding-base-horizontal;
			}
		}

		&--snow {
			&:before {
				content: '';
				background: url('../images/icons/uEA0A-icon_deneigement.svg') center center;
				background-repeat: no-repeat;
				font-family: $ff-glyph;
				font-size: $font-size-base + 5; //25px
				color: $brand-primary;
				padding: $padding-base-horizontal;
			}
		}

		&--work{
			&:before {
				content: '';
				background: url('../images/icons/uEA1C-icon_info_travaux.svg') center center;
				background-repeat: no-repeat;
				font-family: $ff-glyph;
				font-size: $font-size-base + 5; //25px
				color: $brand-primary;
				padding: $padding-base-horizontal;
			}
		}

		&--circulation{
			&:before {
				content: '';
				background: url('../images/icons/uEA15-icon_entretien_routes.svg') center center;
				background-repeat: no-repeat;
				font-family: $ff-glyph;
				font-size: $font-size-base + 5; //25px
				color: $brand-primary;
				padding: $padding-base-horizontal;
			}
		}

		&--enviro{
			&:before {
				content: '';
				background: url('../images/icons/uEA16-icon_environnement.svg') center center;
				background-repeat: no-repeat;
				font-family: $ff-glyph;
				font-size: $font-size-base + 5; //25px
				color: $brand-primary;
				padding: $padding-base-horizontal;
			}
		}

		&--accpt {
			margin-left: $form-group-margin-bottom;
			padding-left: 0;
			padding-right: 0;
		}

	}


	&__formgroup {
		@extend .col-sm-6;
		margin-bottom: $grid-gutter-width /2 ; //20px
	}

    &__formgroup--full {
		@extend .col-sm-12;
		margin-bottom: $grid-gutter-width /2 ; //20px
	}

	&__selector {
		@extend .form-helpbar__col;
		position: relative;
		display: block;
		width: $container-tablet;//100%
		max-width: inherit;
		margin: inherit;
		margin-bottom: 0;
	}

}

.confirmation {
	@extend %clearfix;
	position: relative;
	height: 45px;
	width: 100%;
	border-top: 1px solid $table-border-color;

	.checkgroup {
		@extend .col-md-12;
	}
}

//override style inline
.freeform .form-rating-field-wrapper:not(:checked)>input {
	top: 0 !important;
}

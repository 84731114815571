/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 2015-03-09, 16:41:41
    Author     : chm
*/
 
$screen-xl:                  1440px !default;
$screen-xl-min:              $screen-xl !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-xl-desktop:          $screen-xl-min !default;
 
 
 
$screen-lg-max:              ($screen-xl-min - 1) !default;
 
$container-xlarge-desktop:      (1380px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-xl:                 $container-xlarge-desktop !default;
 
 
 
.container {
  @include container-fixed;
 
 
  @media (min-width: $screen-xl-min) {
    width: $container-xl;
  }
}
 
 
// xLarge grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.
 
@media (min-width: $screen-xl-min) {
  @include make-grid(xl);
}
 
 
 
 
// Generate the xlarge columns
@mixin make-xl-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);
 
  @media (min-width: $screen-xl-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-xl-column-offset($columns) {
  @media (min-width: $screen-xl-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-xl-column-push($columns) {
  @media (min-width: $screen-xl-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-xl-column-pull($columns) {
  @media (min-width: $screen-xl-min) {
    right: percentage(($columns / $grid-columns));
  }
}
 
 
 
@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xl-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xl-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
  }
}
 
 
 
@include responsive-invisibility('.visible-xl');
 
 
.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block {
  display: none !important;
}
 
.visible-lg-block {
  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    display: block !important;
  }
}
.visible-lg-inline {
  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    display: inline !important;
  }
}
.visible-lg-inline-block {
  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    display: inline-block !important;
  }
}
 
 
@media (min-width: $screen-xl-min) {
  @include responsive-visibility('.visible-xl');
}
.visible-xl-block {
  @media (min-width: $screen-xl-min) {
    display: block !important;
  }
}
.visible-xl-inline {
  @media (min-width: $screen-xl-min) {
    display: inline !important;
  }
}
.visible-xl-inline-block {
  @media (min-width: $screen-xl-min) {
    display: inline-block !important;
  }
}
 
 
@media (min-width: $screen-lg-min) and  (max-width: $screen-lg-max){
  @include responsive-invisibility('.hidden-lg');
}
 
@media (min-width: $screen-xl-min) {
  @include responsive-invisibility('.hidden-lg');
}
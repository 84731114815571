// ==========================================================================
// Layouts
// ==========================================================================

main {
	@extend %clearfix;
	background: url(../images/bg-gray.jpg) top center no-repeat;
	background-size: 100% auto;
    transition: transform 0.3s;
}

section {
    margin-top: 2rem;
	margin-bottom: 2rem;

	@media(min-width: $screen-sm) {
		margin-top: 4rem;
		margin-bottom: 4rem;		
	}
}

.page {
	position: relative;
	height: 100%;
}

.container-fluid {
	padding: 3rem 1.25rem;
	background: url(../images/bg-color.jpg) top center no-repeat;
}

.container.search + section {
	.form-helpbar__title {
		color: white;
	}
}

.row--flex  {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;

	@media (max-width: 767px) {
		& > div {
			flex: 1 0 100%;
		}
	}
}


@import "grids-custom";
@import "navbars";
@import "header";
@import "footer";
@import "forms";
@import "notfound";
@import "search";
@import "calendar";

// ==========================================================================
// Pagination
// ==========================================================================

.pagination {
    display: block;
    @include rem('margin-top', 30px);
    @include rem('padding-top', 30px);

    .fa {
        vertical-align: baseline;
    }

    a {
        cursor: pointer;
    }

    a[disabled]{
        pointer-events: none;
        color: $gray-light;
        background-color: #eee;
    }

  // old pagination, we use Bootstrap pagination

  // a + a,
  // a + span,
  // span + a {
  //   @include rem('margin-left', 20px);
  // }
  //
  // span:before {
  //   @include rem('line-height', 26px);
  // }
  //
  // a[disabled]{
  //   display: none;
  // }
  //
  // a:hover,
  // .current {
  //   color: $brand-primary;
  //   text-decoration: none;
  // }
}

// overwrite bootstrap

.pagination-lg {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 $grid-gutter-width / 2;




    & > li {
        & > a:hover {
            background-color: #eee;
        }   
    
        & > span:hover {
            color: $brand-primary;
            background-color: transparent;
        }

        & > span.current:hover {
            color: $c-white;
            background-color: $brand-primary;
        }

        & > span.current {
            background-color: $brand-primary;
            color: $c-white;
        }        
    }

    @media (max-width: $screen-xs-max) {
        & > li {
            flex-grow: 1;
            text-align: center;
            & > a,
            & > span {
                padding: 5px 8px;
                font-size: 14px;
                width: 100%;
                border-right: 1px solid #ddd;
                border-left: 0;
                margin-left: 0;
            }
        }
    }
}


// ==========================================================================
// Today
// ==========================================================================

.today:not(.flatpickr-day) {
    z-index: 3;
    text-align: left;
    background-color: darken($brand-primary, 10%);
    padding: $grid-gutter-width / 2;


    &__notice {
        @extend .row;
    }

    &__title {
        margin: 0 0 10px;
        font-family: $ff-base;
        font-weight: 700;
        color: $c-white;
        text-transform: uppercase;
    }

    hr {
        margin: 0 0 0;
        opacity: .5;
    }

    &__notice__status {
        border-top: $c-white 1px solid;
        margin-top: .5rem;
        padding-top: .5rem;
    }

    &__notice {
        position: relative;
        margin-bottom: $grid-gutter-width / 2;

        &__icon {
            position: relative;
            width: em(45px);
            height: em(45px);
            border-radius: 100%;
            border: 2px solid $c-white;
            color: $c-white;
            margin: 0 auto $grid-gutter-width / 3;
            display: none;

            @media (min-width: $screen-sm) {
                display: block;
            }

            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                line-height: em(23px);
                line-height: 1;
            }
        }

        &__title {
            padding-top: em(3px, 18px);
            font-size: 18px;
            font-weight: 600;
        }

        &__subtitle {
            font-size: 12px;
            line-height: 15px;
            font-weight: 600;
            text-transform: uppercase;
            margin-top: 5px;
        }

        &__mail {
            position: relative;
            padding-right: em(30px, 18px);
            font-size: 18px;
            text-transform: uppercase;
            cursor: pointer;

            &:hover,
            &:focus,
            &:active {
                color: $c-white;
            }

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: em(5px 0 5px 10px, 18px);
                border-color: transparent transparent transparent $c-white;
            }
        }
    }
    
    @media (min-width: $screen-sm) {
        background-color: transparent;
        position: absolute;
        top: $grid-gutter-width;
        right: em(40px);
        width: em(390px);
    }       


    @media (min-width: $screen-md) {
        padding: $grid-gutter-width / 2 0;

        top: 18%;

        .today__notice {
            padding: 0 2.1rem;
        }

        .today__title {
            margin-top: 0;
            color: $c-white;
        }        

    }

    @media (min-width: $screen-lg) {
        hr {
            margin-bottom: 0;
        }
    }
}


.search__form {
    text-align: center;

    .input-group {
        margin-top: 0;
    }

    .btn {
        vertical-align: text-bottom;
    }

    .fa {
        font-size: em(24px);
    }
}

.search__results-count {
    font-family: $ff-alt;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 15px;
    text-transform: uppercase;

    em {
        font-style: normal;
        color: $brand-primary;
    }
}

.search__results {
    list-style: none;
}

.search__result {
    padding: 20px;
    position: relative;
    overflow: hidden;

    @media screen and (min-width: $screen-md) {
        align-items: center;
        display: flex;
        padding-bottom: 40px;
        padding-top: 40px;
    }
    
    &::before {
        $size: 2px;

        background-image: radial-gradient(circle, rgba($brand-primary, .5) $size/2, transparent $size/2);
        background-repeat: repeat-x;
        background-size: 6px $size;
        content: '';
        height: $size;
        display: block;
        position: absolute;
        top: 0;
        width: calc(100% - 40px);
    }

    .btn {
        flex-shrink: 0;
        padding-bottom: 6px;
        padding-top: 6px;
        white-space: nowrap;

        @media screen and (min-width: $screen-md) {
            margin-left: 40px;
        }
    }
}

.search__results-link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.search__result-media {
    background: #efefef;
    flex-shrink: 0;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 100%;
        padding-top: 56.25%;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media screen and (min-width: $screen-md) {
        margin-bottom: 0;
        margin-right: 40px;
        width: 284px;
    }
}

.search__result-title {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 10px;
}

.search__result-content {
    font-size: 20px;
    font-family: $ff-alt;
    flex-basis: 55%;
    align-self: flex-start;
    text-align: left;
}
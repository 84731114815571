// ==========================================================================
// Lists
// ==========================================================================

.list {
  &--unordered,
  &--ordered {
    margin-left: 0;
    @include rem('margin-bottom', 20px);

    @media (min-width: $screen-sm) {
			@include rem('margin-left', 30px);
		}	

    li {
      position: relative;
      @include rem('padding-left', 30px);

      &:before {
        content: '';
        position: absolute;
        left: 0;
      }

      &:not(:last-child) {
        @include rem('margin-bottom', 10px);
      }

      ul,
      ol {
        @include rem('margin', 10px 0 0);
      }
    }
  }

  &--unordered {
    li {
      &:before {
        @include rem('top', 9px);
        @include rem('width', 12px);
        @include rem('height', 12px);
        border-radius: 100%;
        background-color: $brand-primary;
      }
    }
  }

  &--ordered {
    counter-reset: item;

    li {
      &:before {
        content: counters(item, '.') '. ';
        counter-increment: item;
        color: $brand-primary;
      }

      ol {
        counter-reset: item;
      }
    }
  }

  // ==========================================================================
  // Lists / Events
  // ==========================================================================

  $s-list--events-padding-top: $grid-gutter-width/2 - 5;

  &--events {
    color: $brand-primary;

    li {
      position: relative;
      min-height: em($grid-gutter-width*2);
      padding: em($s-list--events-padding-top 175px $grid-gutter-width/2 135px);
      overflow: hidden;
    }

    .list__date {
      position: absolute;
      top: em($grid-gutter-width/2);
      left: 0;
      line-height: em($grid-gutter-width/2);
      text-align: center;

      span {
        display: block;
        color: $gray;
        text-transform: uppercase;

        &:first-child {
          font-size: em(24px);
          font-weight: 700;
        }

        &:last-child {
          font-size: em(14px);
        }
      }
    }

    .list__title {
      display: inline-block;
      font-family: $ff-alt;
      padding-top: em(6px);
      text-transform: uppercase;
      font-weight: 600;
    }

    .list__subtitle {
      display: inline-block;
      font-family: $ff-alt;
      padding-top: em(3px);
      text-transform: none;
    }

    .list__tag {
      position: absolute;
      top: em($s-list--events-padding-top + 5);
      left: em(70px);
      @extend .tag;
      margin-right: em($grid-gutter-width);

      &:before {
        font-size: em(26px);
        color: #c7c7c7;
      }
    }

    .lieu {
      color: #848484 !important;
      font-size: 14.3px !important;
      font-weight: 700;
      text-transform: uppercase;
    }

    .list__hour,
    .list__btn {
      position: absolute;
      top: em($s-list--events-padding-top + 6);
      right: em(10px);
      font-family: $ff-alt;
      text-transform: uppercase;
    }

    .list__btn {
      @extend .btn;
      @extend .btn--small;
      @extend .btn--primary;
    }

    .tab__content {
      font-size: em(16px);
    }
  }
}

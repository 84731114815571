// ==========================================================================
// Back link
// ==========================================================================

.back-link {
  margin-bottom: 6rem;
  font-size: em(14px);
  text-align: center;
  text-transform: uppercase;

  .btn-icon {
    box-shadow: none;

    &:before {
      line-height: em(64px, 34px);
      cursor: pointer;
      pointer-events: none;
    }
  }

  div {
    margin-top: em(10px, 14px);
  }
}

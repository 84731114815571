.grid {

// ==========================================================================
// Grid Custom
// ==========================================================================

  &--custom {
    @extend .row;

    &__col {
      @include make-sm-column(6);
    }

    &__large {
      @include make-xm-column(12);
      .thumbnail {
        font-size: 1rem;
      }

    }

    &__small {
      @include make-xm-column(6);
      .thumbnail {
        font-size: .75rem;
      }
    }

    &__large,
    &__small {
      & > img {
        width: 100%;
        margin-bottom: $grid-gutter-width;
      }
    }
  }

// ==========================================================================
// Grid Services
// ==========================================================================

  &--services {
    @extend %clearfix;

    .service {
      @include make-xm-column(6);
      border-bottom: 1px solid $gray-lighter;

      &:not(.district) {
          @include make-sm-column(3);
      }

      &.district {
          @include make-sm-column(4);
      }

      @media (min-width: $screen-xs) {
        border-right: 1px solid $gray-lighter;
      }

      @media (min-width: $screen-xs) and (max-width: $screen-sm) {
        &:nth-child(2n) {
          border-right: 0;
        }

        &:nth-last-child(-n+2) {
          border-bottom: 0;
        }
      }

      @media (min-width: $screen-sm) {
        &:not(.district):nth-child(4n) {
          border-right: 0;
        }

        &:not(.district):nth-last-child(-n+4) {
          border-bottom: 0;
        }

        &.district {
            &:nth-child(3n) {
                border-right: 0;
            }

            &:nth-last-child(-n+3) {
              border-bottom: 0;
            }
        }
      }
    }
  }
}

// ==========================================================================
// Typography
// ==========================================================================

.typography {
    &__p {
        margin-bottom: em(60px);
        text-align: center;

        &--small {
            margin: em(30px 0 45px, 16px);
            font-size: em(16px);
            text-align: center;
        }
    }
}

// ==========================================================================
// Typography / Wywiwyg
// ==========================================================================

.wysiwyg {
    overflow: hidden;

    & > *:first-child:not(.section__title) {
        margin-top: .5rem;
    }

    br:first-child {
        display: none;
    }

    h2 {
        margin-bottom: 1.75rem;
        border-bottom: 1px solid $gray-lighter;
        font-family: $ff-base;
        @include rem('font-size', $fs-base);
        line-height: $lh-base;
        color: $brand-primary;
        clear: both;
    }

    .wysiwyg-table {
        overflow-x: scroll;
        white-space: nowrap;
        min-width: 500px;

        @media (min-width: $screen-sm) {
            overflow-x: auto;
            white-space: normal;
            min-width: none;
        }
    }

    h3 {
        line-height: 1.2;
    }

    h4.intro {
        @include rem('font-size', 20px);
        text-transform: none;
        line-height: 1.5;

        @media (min-width: $screen-sm) {
            @include rem('font-size', 30px);
            line-height: 30px;
        }
    }

    p {
        margin-bottom: 1.25rem;
        line-height: 1.8;
        color: $gray-base;
        word-break: break-word;
    }

    a:not([class*="btn"]) {
        @extend .link--default;
    }

    img {
        max-width: 100%;
    }

    b,
    strong {
        font-weight: 600;
        color: $brand-primary;
    }

    em,
    i {
        font-style: italic;
    }

    ul:not(.slick-dots) {
        @extend .list--unordered;
    }

    ol {
        @extend .list--ordered;
    }

    input.text,
    textarea {
        @extend .form-control;
        margin-bottom: 1.25rem;
    }

    .btn + .btn {
        margin-top: 1rem;

        @media (min-width: $screen-md) {
            margin-top: 0;
        }
    }

    .section__title {
        margin-top: 36px;
    }
}

// ==========================================================================
// Bullets
// ==========================================================================

.bullets,
.slick-dots {
  margin-top: em(25px);
  text-align: center;

  li {
    display: inline-block;
    width: em(22px);
    height: em(22px);
    border-radius: 100%;
    border: 2px solid $brand-primary;
    position: relative;
    background-color: $c-white;
    text-indent: -9999px;
    z-index: 0;
    cursor: pointer;

    & + li {
      margin-left: em($grid-gutter-width/4);
    }

    &:after {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      opacity: 0;
      z-index: -1;
      display: block;
      height: calc( 100% + 2px );
      width: calc( 100% + 2px );
      background-image: linear-gradient(45deg, #2f9da8, #40a985);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2f9da8', endColorstr='#40a985',GradientType=1 );
      border-radius: inherit;
      transition: opacity .3s;
    }

    &.is-active,
    &.slick-active,
    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
}

// ==========================================================================
// Alerts
// ==========================================================================
.alert {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: em(20px) em(50px) em(30px);
    border-bottom: 2px solid $c-white;
    color: $c-white;
    text-align: center;
    text-transform: uppercase;
    transition: all .3s;

    &__close {
        @extend .icon;
        @extend .icon--cross;
        position: absolute;
        top: 10%;
        right: em(15px, 21px);
        font-size: em(21px);
        transition: transform 0.3s;

        &:after,
        &:before {
            height: em(2px, 21px);
        }
    }

    &__content {
        position: relative;
        display: inline-block;
        font-size: em(24px);

        small {
            display: block;
            font-size: em(14px, 24px);
        }
    }

    &--confirm {
        background-color: #51B873;
    }

    &--warning {
        background-color: $brand-warning;
    }

    &--danger {
        background-color: $brand-danger;

        .alert__content {
            @extend .fc;
            @extend .fc-icon_alertes;

            &:before {
                position: absolute;
                top: em(8px, 36px);
                left: em(-15px, 36px);
                transform: translateX(-100%);
            }
        }
    }

    @media (min-width:$screen-sm) {
        &__close {
            top: 25%;
            right: em(30px, 21px);
        }
    }

    @media (min-width:$screen-md) {
        &__content {
            font-size: em(36px);

            small {
                font-size: em(14px, 36px);
            }
        }
    }
}

.status-tag {
    font-size: 16px;
    padding: .5rem;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-family: $ff-alt;
    text-transform: uppercase;

    div {
        position: relative;
        top: 2px;
        color: $c-white;
    }

    &--open {
        background-color: $brand-primary;
    }

    &--closed {
        background-color: $brand-danger;
    }

    &--small {
        font-size: 16px;
    }

    &--spacing-top {
        margin-top: 1rem;
    }

    &--spacing-bottom {
        margin-bottom: 1rem;
    }    
}

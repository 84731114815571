// ==========================================================================
// Links
// ==========================================================================

.link {
    &--default {
        color: $brand-primary;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &--overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
    }
}

// ==========================================================================
// Forms
// ==========================================================================

.form {
    &--confirmation {
        font-weight: bold;
    }

    @extend %clearfix;
}

label {
	font: normal 20px/1.8 $ff-alt;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="phone"] {
	font-family: $ff-alt;

	@include placeholder {
		color: $gray-light;
		opacity: 1;
		transition: all .3s;
	}

	&:focus {
  	@include placeholder {
    	opacity: .5;
  	}
  }
}

select {
	font: normal 20px/1.8 $ff-alt;
}

.input-group {
    max-width: 500px;
	  margin: $grid-gutter-width auto;

    .form-control {
        border-color: $brand-primary;
    }

    &--multiple {
        max-width: none;

        .form-control {
            width: auto;

            &:not(:first-child) {
                border-left: 0;
                border-radius: 0;
            }
        }

        .input-group-btn {
            width: auto;
        }
    }
}


// ==========================================================================
// Forms / Input File
// ==========================================================================

.input-file {
	position: relative;

	input {
		&[type="file"] {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
		}

		&[disabled] {
			background-color: $c-white;
		}
	}
}
.container.wysiwyg .input-file {
  margin-bottom: 0;
}
.container.wysiwyg .btn__group {
  margin-top: 40px;
}




// ==========================================================================
// Forms / Checkboxes
// ==========================================================================

// Useless for now, was planned in styleguide but not used
// Need refactor


.lightswitch {
    position: relative;
    input[type="checkbox"] {
        margin: 0;
    }
    label {
        margin-bottom: 0;
    }

}


.checkgroup {
	@extend .col-sm-4;
	@extend .col-md-4;
	margin-top: $form-group-margin-bottom;
	margin-bottom: $form-group-margin-bottom;

	& + .label {
		width: inherit;
		float: none;
	}

	&--choice {
		@extend .col-sm-4;
		@extend .col-md-4;
		margin-top: $form-group-margin-bottom;
		margin-bottom: $form-group-margin-bottom;
	}
}

.checkbox:not(.freeform *) {
	display : none;
}

.checkbox {
    &--alt {
        opacity: 0;
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 100%;
        &:checked + .boxe:after {
            height: 100%;
        }
    }

	& + .boxe {
		background-color: $checkbox-bg;
		border: 1px solid $checkbox-border;
		box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
		padding: $padding-large-vertical + 3; //15px
		border-radius: $border-radius-small;
		display: inline-block;
		position: relative;
	}

	&:checked {
		& + .boxe {
			border: 1px solid $checkbox-border;
			box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
			color: $brand-primary;

			&:after {
				content: '\2714';
				font-size: 14px;
				position: absolute;
				top: 0px;
				left: 3px;
				color: $brand-primary;
				font-size: $font-size-base - 2; //18px
				left: $grid-gutter-width / 8 + 3; //8px
			}
		}
	}
}

.form {
    &__note {
        @extend .small;
        @include rem('margin-bottom', 10px);
    }
}

.form__label > label {
    text-transform: none;

    &.required {
        &:after { content: " *"; }
    }
}

.form-select-wrapper {
	max-width: 450px;
	margin: 0 auto 5rem;
	display: flex;
	flex-direction: column;

	@media (min-width: $screen-sm) {
		flex-direction: row;			
	}	
	
	.form-select {
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border-top-right-radius: 30px;
		border-bottom-right-radius: 30px;
		margin-bottom: 1rem;
		display: flex;
		align-items: center;

        @media (min-width: $screen-sm) {
            margin-bottom: 0;
        }

        &:not(.no-btn) {
            @media (min-width: $screen-sm) {
                border-right: none;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.no-btn {
            max-width: 250px;
        }

		select {
			width: 100%;
			cursor: pointer;
			text-align: center;
			-webkit-appearance: menulist;
			-moz-appearance: menulist; 
			appearance: menulist; 

		}
	}

	.btn {
		@media (min-width: $screen-sm) {
			border-left: none;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;	
			padding-left: 12px;
		}
	}
}

.form--inscription {
	.input-group {
		@media(max-width: 767px) {
			display: flex;
			flex-direction: column;

			.form-control {
				border-bottom-right-radius: 30px;
				border-top-right-radius: 30px;
				margin-bottom: 1rem;
				font-size: 16px;
			}

			.input-group-btn {
				margin: 0 auto;
				width: auto;
			}

			.btn {
				border-bottom-left-radius: 30px;
				border-top-left-radius: 30px;
			}
		}
	}
}

// freeform input style 
.freeform {
	.checkbox input[type="checkbox"],
	.radio input[type="radio"]{
		margin-top: 2px;
	}

	.freeform-error {
		border: solid 1px $brand-danger;
		padding: 1rem;
		font-style: italic;
		color: $brand-danger;
		display: block;
		width: fit-content;
		font-size: 1.2rem;
	}
}


// ==========================================================================
// Helpers
// ==========================================================================

%back-grad {
	background-color: #2f9da8;
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMTAwJSIgeDI9IjEwMCUiIHkyPSIwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMmY5ZGE4IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzQwYTk4NSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
	background-image: linear-gradient(45deg, #335356 0, #2f9da8 50%, #2f9da8 50%, #40a985 100%);
	background-size: 200%;
	background-position: 100%;
}

%clearfix {
	&:before,
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

%centerX {
  left: 50%;
  transform: translateX(-50%);
}

%centerY {
  top: 50%;
  transform: translateY(-50%);
}

%centerXY {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

%fa {
  font-family: 'FontAwesome';
  font-weight: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-padding {
	padding: 0;

	&--top { padding-top: 0; }
	&--bottom { padding-bottom: 0; }
	&--left { padding-left: 0; }
	&--right { padding-right: 0; }
}

%img-cover {
    display: block;
    width: 100%;

    & > img,
    picture > img {
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
    }

    // For IE9, IE10 and IE11
    .objectfit {
        display: block;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        z-index: 5;

        img {
            opacity: 0;
        }

        & ~ img {
            display: none;
        }
    }
}

.h-block-lg1 {
  @media screen and (max-width: $screen-lg) {
    display: block;
  }
}

.h-hide-md {
  @media screen and (min-width: $screen-md) {
    display: none !important;
  }
}

.h-md-mt {
  margin-top: em(22px); 
}

.h-flex-center {
  display: flex;
  justify-content: center;
}

.h-flex-align-center {
    align-items: center;
}

.h-color-brand-primary {
    color: $brand-primary;
}


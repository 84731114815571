.navbar {

	&__logo {
		cursor: pointer;
	}
	
	&__group {
		background-size: 100% auto;

		&--home {
			&.is-fixed {
				.navbar__main {
					background: transparent;
				}
			}
		}

        &.navbar__group--map {
            @extend .is-fixed;

            .navbar__top .btn-icon--map {
                display: none;
            }

			@media (min-width: $screen-md) {
				ul.navbar__nav > li > a,
				.navbar__dropdown {
					font-size: .95rem;
					line-height: 3rem;

					.caret {
						top: 29px;
					}
				} 
			}

            @media (min-width: $screen-xl) {
                .navbar__main {
                    height: 80px;
				}
			}
        }

		@media (min-width: $screen-md) {
			&--home {
				&.is-fixed {
					background: url(../images/bg-color.jpg) center center no-repeat;
				}
			}

			&:not(.navbar__group--home) {
				background: url(../images/bg-color.jpg) center center no-repeat;
			}
		}
	}

// ==========================================================================
// Navbars / Top
// ==========================================================================

	&__top {
		padding: 0 em($grid-gutter-width/2) em(15px);
		background: url(../images/bg-color.jpg) center center no-repeat;
		transition: transform .3s;

		.btn-icon--map {
			display: none;

			.navbar__group:not(.navbar__group--home) & {
				span {
					display: none;
				}
			}
		}

		.navbar__logo {
			float: left;
		}

		.navbar__toplinks {
			float: right;
			font-family: $ff-alt;
		}

        .navbar__group--home & {
            z-index: 2;
        }
	}

	&__logo {
		width: em(175px);
		// height: em(68px);
		margin-top: .5em;
		
		img {
			max-width: 100%;
		}
	}

	&__toplinks {
		font-size: em(16px);
		font-weight: 700;
		color: $c-white;

        a {
            cursor: pointer;

            &:hover,
    		&:focus {
    			color: $c-white;
    			text-decoration: none;
			}
			
			&.social {
				font-size: 1.3rem;
				margin: 0 .25rem;
				@media (min-width: $screen-md) {
					margin: 0 .5rem;		
				}
			}
        }

        ul {
			margin-bottom: 0;
			float: right;
        }

		li {
			padding: em(5px, 14px) 0;

			&:not(.navbar__topsearch) {
				display: none;
			}

			&.navbar__topsearch {
				margin-top: em(26px, 14px);
				text-align: right;
				display: none;

				@media (min-width: $screen-sm) {
					display: table-cell;		
				}
			}

			span {
				&:before {
					font-size: 32px;
				}

				&.fa-user {
					span {
						@extend .hidden-sm;
					}
				}

				&[class^='fa']:before {
					@extend %fa;
				}
			}
		}

		@media (max-width: $screen-md) {
			ul {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				.navbar__topsearch {
					margin-top: 0;
				}
			}


		}
	}

	&__contact {
		display: none;
	}

	@media (min-width: $screen-sm) {
		&__top {
            border-top: em($grid-gutter-width/4) solid transparent;

			.btn-icon--map {
				position: absolute;
				top: em(25px, 20px);
				left: 50%;
				transform: translateX(-50%);
				display: block;
				font-size: 20px;

				span {
					display: none;
				}
			}

			.navbar__group--home.is-fixed & {
				.btn-icon--map {
					span {
						display: none;
					}
				}
			}
		}

		&__toplinks {
			font-weight: 700;

			ul {
				display: table;
				float: right;

				li {
					display: table-cell;
					vertical-align: middle;
				}
			}

			li {
				&:not(.navbar__topsearch) {
					display: table-cell;
				}

				&:first-child {
					padding-right: em(15px, 14px);
				}

				&:last-child {
					padding-right: 0;
				}

				& + li {
					margin-left: em(10px, 14px);
					padding: 0 em(10px, 14px);
					border-left: 1px solid $c-white;
				}

				span {
					&:before {
						font-size: 26px;
					}

					&.navbar__topsearch {
						margin-top: 0;
					}

					&.fa-user {
						&:before {
							margin-right: em(5px);
						}
					}

					&.fc-icon_alertes:before {
						font-size: em(30px, 14px);
						text-shadow: 0 0 25px saturate($brand-danger, 20%);
					}
				}
			}
		}

		&__contact {
			display: block;
			margin-bottom: em(12px, 14px);
			line-height: em(24px, 14px);
			text-align: right;

			a[href^=tel] {
				display: inline-block;
				margin-left: em(5px, 14px);
				font-size: em(18px, 14px);
				@media (min-width: $screen-md) {
					margin-left: em(10px, 14px);
				}
			}
		}
	}

	@media (min-width: $screen-md) {
        &__top {
            padding-bottom: 0;
        }

		&__logo {
			width: em(250px);
			// height: em(83px);
			left: -$grid-gutter-width*2;
            margin-top: 0;

			.navbar__group--home.is-fixed & {
				margin-top: em(5px);
				width: em(205px);
			}

			.navbar__group:not(.navbar__group--home) & {
				top: -100px;
			}

			.navbar__group:not(.navbar__group--home).is-fixed & {
				position: absolute;
				top: auto;
				bottom: -62px;
				left: -4vw;
				transform: translateY(20%) scale(.6);
			}
		}

		&__top {
			border-top: em($grid-gutter-width/2) solid transparent;
			background: none;

			.btn-icon--map {
				top: em(30px, 20px);
			}

			.navbar__group--home.is-fixed & {
				.btn-icon--map {
					top: em(25px, 20px);
				}
			}
		}

        &__contact {
			margin-bottom: em(15px, 14px);
		}
	}

	@media (min-width: 1160px) {
		&__logo {
			.navbar__group:not(.navbar__group--home).is-fixed & {
				left: -3vw;
			}
		}
	}

	@media (min-width: $screen-xl) {
		&__logo {
			width: em(305px);
			// height: em(101px);

			.navbar__group--home.is-fixed & {
				margin-top: 0;
				width: em(250px);
				// height: em(83px);
			}

			.navbar__group:not(.navbar__group--home).is-fixed & {
				transform: translateY(35%) scale(.6);
			}
		}

		&__top {
			.btn-icon--map {
				top: em(10px, 26px);
				font-size: em(26px);

				span {
					display: block;
				}
			}

			.navbar__group--home.is-fixed & {
				.btn-icon--map {
					top: em(10px, 26px);
				}
			}
		}

		&__toplinks {
			li {
				& + li {
					margin-left: em(15px, 14px);
					padding: 0 em(15px, 14px);
				}
			}
		}
	}

// ==========================================================================
// Navbars / Main
// ==========================================================================

	&__main {
		background-size: 100% auto;
		box-shadow: 5px 0 20px 0 rgba(50, 50, 50, 0.4);

		.navbar__group--home & {
			background-color: $c-white;
			background-clip: content-box;
	  	}

		.navbar__group--home.is-fixed & {
			padding-bottom: 0;

			&:after {
				content: none;
			}
		}

		@media(min-width: $screen-md) {
			box-shadow: none;

			.navbar__group--home & {
				background: url(../images/bg-color.jpg) center center no-repeat;
			}
		}
	}

	&__toggle {
		float: left;
		margin: em(42px 15px 0 0, 26px);
        @include rem('font-size', 26px);

		@media (min-width: $screen-md) {
			display: none;
		}
	}

	&__nav {
		margin-bottom: 0;
		padding: em(20px 0);

		& > li {
			& > a {
				display: block;
				padding: em(12px 24px);
				font-family: $ff-alt;
				font-size: 16px;
				color: $brand-primary;
				cursor: pointer;

				&:hover,
                &:focus,
                &:active {
					background-color: transparent;
					text-decoration: none;
				}
			}
		}

		@media(min-width: $screen-md) {
			padding: 0;

			& > li {
				& > a {
					height: 60px;
					font-size: 1.5vmax;
					color: $c-white;
					line-height: calc( 60px - #{em(24px)} );
					text-transform: uppercase;
				}
			}
		}

		@media(min-width: $screen-xl) {
			& > li > a {
				height: em(80px, 21px);
				line-height: em(56px, 21px);
				font-size: 21px;
			}
		}

		@media(min-width: $screen-xl) and (max-width: 1530px) {
			& > li > a {
				font-size: 1.3vmax;
			}
		}
	}

	&__dropdown {
		position: relative;
		padding: em(12px 60px 12px 24px);
		font-family: $ff-alt;
		font-size: 16px;
		color: $brand-primary;
		text-align: left;
		cursor: pointer;
		transform: translateZ(0);

		.caret {
			position: absolute;
			top: 46%;
			transform: translateY(-50%);
			right: 1em;
			color: $brand-primary;
		}

		@media(min-width: $screen-md) {
			min-height: 60px;
			line-height: calc( 60px - #{em(24px)} );
			font-size: 1.5vmax;
			color: $c-white;
			text-transform: uppercase;

			.caret {
				color: $c-white;
			}
		}

		@media(min-width: $screen-xl) {
			height: em(80px, 21px);
			line-height: em(56px, 21px);
			font-size: 21px;

			.caret {
				top: 46%;
				right: em(25px, 21px);
			}
		}

		@media(min-width: $screen-xl) and (max-width: 1530px) {
			font-size: 1.3vmax;
		}

		&.is-open {
			.caret {
				transform: translateY(-50%) rotateX(-180deg);
			}
		}
	}

	@media(min-width: $screen-md) {
		&__main {
			.navbar__group:not(.navbar__group--home).is-fixed & {
				margin-top: em(60px);
		    }
		}

		&__group:not(.navbar__group--home).is-fixed {
			.navbar__dropdown {
				padding-left: 12px;
				padding-right: 30px;

				.caret {
					right: 5px;
				}
			}
		}

		&__nav {
			padding: 0;
		}
	}

	@media(min-width: $screen-lg) {
		&__main {
			.navbar__group:not(.navbar__group--home).is-fixed & {
				margin-top: em(20px);
		    }
		}
	}	

	@media(max-width: $screen-sm-max) {
		&__main {
			position: fixed;
			top: 0;
			left: 0;
            bottom: 0;
			width: 260px;
            min-height: 101%;
            overflow-x: hidden;
            overflow-y: scroll;
			background-color: $c-white;
			transform: translate3d(-100%,0,0);
			transition: transform .3s;
            opacity: 0;
			z-index: 1001;
            -webkit-overflow-scrolling: touch;
		}
	}

	@media(min-width: $screen-xl) {
		&__main {
			.navbar__group:not(.navbar__group--home).is-fixed & {
				.dropdown-toggle {
					padding-right: em(60px);

					&.btn .caret {
						right: 20px;
					}
				}
			}
		}

		&__group:not(.navbar__group--home).is-fixed {
			.navbar__dropdown {
				padding-right: em(60px);

				.caret {
					right: 20px;
				}
			}
		}
	}

	@media (min-width: 1500px) {
		&__top,
		&__main {
			.navbar__group:not(.navbar__group--home).is-fixed & {
				width: calc( 100% - #{$grid-gutter-width} );
				max-width: 1500px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

}

.has-mobilenav-opened {
	.main,
    .footer,
	.navbar__top,
    .alert,
    .header,
    .header--home {
		transform: translate3d(260px,0,0) !important;
	}

    .page {
        overflow: hidden;
    }

	.navbar__main {
		transform: translate3d(0,0,0);
		opacity: 1;
	}
}


.navbar__toplinks {
	@media(max-width: $screen-xs) {
		float: none;
		clear: both;
	}
}

.navbar__top .navbar__toplinks {
	@media (max-width: $screen-xs) {
		float: none;
	}
}

.navbar__toplinks ul {

	@media (max-width: $screen-xs) {
		float: none;
	}	

	.navbar__topsearch {	

		@media (max-width: $screen-xs) {
			margin-top: 1rem;
			width: 100%;
		}

		.fa {
			cursor: pointer;
		}

		form {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			input {
				background-color: $c-white;
				color: $c-base;
				padding: 0 .5rem;
				width: 100%;
				margin-right: .5rem;
				min-height: 36px;
				font-weight: normal;
			}	
		}

		[trigger-search-target] {
			display: none;
			
			&.is-active {
				display: block;
			}
		}
	}
}

.navbar__toplinks--mobile {
	color: $brand-primary;
	padding: 0 em(24px);
	text-align: left;
	display: block;
	padding-bottom: 1rem;

	@media (min-width: $screen-md) {
		display: none !important;
	}

	ul {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 1rem;
		flex-wrap: wrap;
		float: none;
	}

	li {
		display: block !important;
		margin-left: 0 !important;
		padding-left: 0 !important;
		margin-bottom: .5rem;

		span.fa-user span {
			display: inline !important;
		}
	}


	.navbar__contact {
		display: block;
		text-align: left;

		@media (min-width: $screen-md) {
			text-align: right;
		}	

		a {
			color: $brand-primary;

			&:first-child {
				margin-bottom: 1rem;
			}

			&:last-child {
				display: block;
			}
		}
	}

	.navbar__topsearch {
		flex: 1 0 100%;
		input {
			border: $brand-primary 1px solid;
		}
	}

	.navbar__toplinks-icon {
		display: flex;
		align-items: center;

		&::before {
			font-size: 20px;
			margin-right: .5rem;
		}
	}
}


.navbar__nav {
	.navbar__toplinks:first-child {
		padding-bottom: 0;
		ul {
			margin-top: 0;
		}

		.navbar__topsearch {
			margin-top: 0;
		}
	}
}


.navbar__top {
	@media (max-width: 1024px) {

		display: flex;
		align-items: center;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;

		.navbar__logo--mobile {
			align-self: center;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			width: 230px;
			// height: 33px;
			margin-top: 0;
			padding-left: 1.5rem;
		}

		.navbar__toplinks {
			flex-grow: 1;
		}

		.navbar__toggle {
			margin: 0;
		}
	}
}

.navbar__logo--desktop {
	display:none;

	@media (min-width: $screen-md) {
		display: block;
	}
}

.navbar__logo--mobile {
	display: flex;

	@media (min-width: $screen-md) {
		display: none;
	}
}

.navbar__menu > ul a {
	@media (max-width: 1023px) {
		font-family: Arial, Helvetica, sans-serif !important;
	}
}
// ==========================================================================
// Date range
// ==========================================================================

.date-range {
  @extend .row;
  border-top: 1px solid $gray-lighter;
  margin-top: em(35px);
  margin-bottom: em(35px);
  padding-top: em(25px);

  div {
    max-width: 650px;
    @include make-xm-column(6);

    & + div {
      margin-top: em($grid-gutter-width);
    }
  }

  strong {
    display: block;
    font-size: em(18px);
    text-transform: uppercase;
  }

  @media (min-width: $screen-xs) {
    div + div {
      margin-top: 0;
    }
  }
}

// ==========================================================================
// Page titles
// ==========================================================================

.page {
    &__title {
        @extend .section__title;
        margin-top: 76px;

        .btn-icon {
            margin-bottom: 1rem;
            display: block;
            margin-left: auto;
            margin-right: auto;

            @media (min-width: $screen-xs) {
                margin-top: 66px;
                position: absolute;
                top: -20px;
                left: 50%;
                transform: translate(-50%, -100%);
                margin-top: 0;            
            }
        }

        &--banner {
            margin: 0;
            padding-top: 20px;
            padding-bottom: 20px;
            background-repeat: no-repeat;
            background-size: cover;
            color: $c-white;

            @media (min-width: $screen-xs) {
                padding-top: 65px;
            }

            &:after {
                content: none;
            }

            .btn-icon {
                // top: 46px;
                z-index: 6;
                @media (min-width: $screen-xs) {
                    top: 46px;
                }
            }

            span {
                font-size: 1.5rem;

                @media (min-width: $screen-sm) {
                    font-size: 2.25rem;
                }
            }

            span:first-of-type {
                position: relative;
                display: inline-block;
                z-index: 6;

                &:after {
                    content: '';
                    height: 2px;
                    width: 74px;
                    position: absolute;
                    bottom: -8px;
                    left: 50%;
                    margin-left: -36px;
                    background-color: $c-white;
                }
            }

            small {
                color: $c-white;
            }

            .input-group {
                margin: 20px auto;

                span:first-of-type {
                    display: table-cell;

                    &:after {
                        display: none;
                    }
                }

                .btn--primary:focus {
                    box-shadow: inset 0 1px 3px transparentize($c-base, .6)
                }

                .form-control:focus {
                    box-shadow: none;
                }
            }

            @media (min-width: $screen-xs) {
                height: 315px;
            }
        }

        &__content {
            padding-top: 20px;
            z-index: 6;

            @media (min-width: $screen-xs) {
                left: 0;
                bottom: 20px;
                width: 100%;

                &.banner {
                    position: absolute;
                }
            }
        }

        &__opening {
            font-size: 26px;
            font-weight: 500;
            &--offset {
                margin-top: 53px;

                & + .btn__group--center--banner {
                    margin-top: 43px;
                }
            }

            .next-hours {
                display: block;
                padding-top: 20px;
            }
        }

        &__links {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 700;
            color: $brand-primary;
            text-decoration: underline;

            a {
                display: block;
                cursor: pointer;

                & + a {
                    margin-top: 10px;
                }
            }

            @media (min-width: $screen-xs) {
                a {
                    display: inline;

                    & + a {
                        position: relative;
                        margin-top: 0;
                        padding-left: 21px;

                        &:before {
                            position: absolute;
                            top: 0;
                            left: 10px;
                            content: '|';
                            color: $c-white;
                        }

                        &:hover {
                            &:before {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }

        &--submenupage {
            @extend .page__title;
            @extend .page__title--banner;
        }

        &--business {
            @extend .page__title;
            @extend .page__title--banner;
            background-image: url('../images/bg-business.jpg');
        }

        &--library {
            @extend .page__title;
            @extend .page__title--banner;
            background-image: url('../images/bg-library.jpg');

            @media(max-width: ( $screen-xs - 0.001em )) {
                .page__title__opening {
                    padding-top: 20px;
                }

                .input-group {
                    display: block;

                    .form-control {
                        display: block;
                        float: none;
                        margin: 10px auto;

                        &:first-child {
                            border-bottom-right-radius: 30px;
                            border-top-right-radius: 30px;
                        }

                        &:not(:first-child):not(:last-child) {
                            border-radius: 30px;
                        }
                    }

                    span:first-of-type {
                        display: block;
                        margin: 10px auto;
                    }
                }

                .input-group-btn:last-child > .btn {
                    border-bottom-left-radius: 30px;
                    border-top-left-radius: 30px;
                }
            }

            @media (min-width: $screen-xs) {
                .page__title__opening {
                    display: block;
                }

                .input-group {
                    display: table;
                }
            }
        }

        &--has-not-banner {
            &::after {
                content: none;
            }

            span:first-of-type {
                &::after {
                    content: "";
                    display: block;
                    height: 2px;
                    width: 74px;
                    margin: 10px auto 0;
                    position: relative;
                    left: auto;
                    bottom: auto;
                    background-color: $c-white;                  
                }
            }

            &:not(.page__title--library) {
                span:first-of-type {
                    &::after {
                        @extend %back-grad;                 
                    }
                }                
            }
        }
    }
}

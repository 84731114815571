// ==========================================================================
// Cards
// ==========================================================================

.card {
  position: relative;
  @extend %clearfix;
  background-color: $c-white;
  text-align: center;

  img {
    @include make-sm-column(3);
    max-width: 100%;
    padding: 0;
  }

  &__title {
    margin-bottom: em(20px);
    font-family: $ff-alt;
    font-size: em(28px);
    color: $brand-primary;
    text-transform: uppercase;

    small {
      display: block;
      font-family: $ff-base;
      font-size: 50%;
      color: $gray-base;
    }
  }

  &__content {
    @include make-sm-column(9);
    margin-top: $grid-gutter-width/2;
    padding: 0 $grid-gutter-width/2;
  }

  .btn--primary:not(.btn-icon) {
    display: block;
    margin: 0 auto;
  }

  .btn-icon {
    @extend .btn-icon--small;
    margin-top: $grid-gutter-width/4;
  }

  @media (min-width: 479px) {
    .btn--primary:not(.btn-icon) {
      display: inline-block;
    }

    .btn-icon {
      margin-top: 0;
    }
  }

  @media (min-width: $screen-sm) {
    text-align: left;

    &__content {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      margin-top: 0;
      padding: 0 $grid-gutter-width;
    }
  }
}

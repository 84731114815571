.c-modal {
    position: absolute;
    top: 0;
    width: 100%;
    padding-bottom: 60px;
    background-color: $c-white;
    opacity: 0;
    visibility: hidden;
    z-index: 999;

    &.is-open {
        opacity: 1;
        visibility: visible;
    }

    .btn-icon--map {
        position: absolute;
        bottom: em(-30px, 26px);
        left: 50%;
        transform: translateX(-50%);

        span {
            top: 0;
            transform: translate(-50%, -100%);
            color: $brand-primary;
        }
    }
}

@media (min-width: 64.0625em) {
    .modal-open {
        .navbar__group {
            padding-right: 15px;
        }
    }
} // 1025px to disable it for all mobile devices including ipad lanscape

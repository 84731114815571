// ==========================================================================
// Dropdown & selects
// ==========================================================================

.dropdown {
	&--select {
		[data-original-index='0'] {
			display: none;
		}
	}

	&__toggle {
		position: relative;
		padding: em(11px 60px 12px 24px, 20px);
		border-radius: $border-radius-base;
		text-align: left;

		.bootstrap-select & {
			&.btn {
				padding-right: 60px;

				.caret {
					@extend %centerY;
					right: em(20px);
				}
			}
		}

		.bootstrap-select:not(.caret--triangle) & {
			&.btn {
				.caret {
					width: auto;
					height: auto;
					@extend .caret--angle;
					transform-origin: 50% 55%;
				}
			}
		}

		.filter-option {
			color: $gray-light;
		}

        &:hover,
        .open>&:hover,
		.btn-group.open &:hover {
            background: white;
        }

		&:focus,
		&:active,
		.open>&,
		.btn-group.open &,
		.open>&:active,
		.btn-group.open &:active,
		.open>&:focus,
		.btn-group.open &:focus {
			background: white;
			box-shadow: none;
			border-color: $gray-lighter;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;

			.bootstrap-select.btn-group &.btn .caret,
			&.btn .caret {
				transform: translateY(-50%) rotateX(-180deg);
			}
		}
	}

	&__menu {
		min-width: 100%;
		font-family: $ff-alt;
		transform: translateZ(0);

		& > li {
			&:focus {
				outline: 0;
			}

			a {
				padding: em(13px $grid-gutter-width);
				transition: all .3s;
				outline: 0;
                white-space: normal;
                line-height: 1.2;

				&:hover {
					color: $c-white;
					background-color: $brand-primary;
				}
			}
		}
	}
}

// Extend bem classes to bootstrap
.dropdown-toggle {
	@extend .dropdown__toggle;

	// remove boostrapp attributes
	.bootstrap-select & {
		&.btn {
			text-transform: none;

			&:focus {
				outline: 0 !important;
			}

			.caret {
				margin: 0;
			}
		}
	}
}

.dropdown-menu {
	@extend .dropdown__menu;

	// remove boostrapp attributes
	padding: 0;
	margin: 0;
	border-top: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	box-shadow: none;

	.divider {
		margin: 0;
	}
}

// ==========================================================================
// Dropdown caret
// ==========================================================================

.caret {
	width: auto;
	height: auto;
	border: 0;
	@extend %fa;
	@extend .fa-caret-down;
	font-size: 1.2em;
	color: $brand-secondary;
	transition: transform .3s;

	&--angle {
		@extend .fa-angle-down;
		@include rem('font-size', 36px);
		position: absolute;
		@include rem('right', 20px);
	}

	.dropup &,
	.dropdown & {
		border: 0;
	}
}

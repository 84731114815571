// ========================================================
// Base
// ========================================================

html, body {
    width: 100%;
    height: 100%;
}

body {
    overflow-x: hidden;
}

svg {
    max-width: 100%;
}

@import "typo";

// ==========================================================================
// Header menus
// ==========================================================================

.navbar__menu {
    padding: em(20px, 16px) 0;
    font-family: $ff-alt;
    font-size: 16px;
    color: $brand-primary;
    text-align: left;
    background-color: $c-white;
    transform: translateZ(0);

    & > ul {
        position: relative;
        @extend .container;

        ul {
            position: static;

            li {
                margin-bottom: em(16px, 18px);
                font-size: 14px;
            }

            a {
                position: relative;
                box-decoration-break: clone;
                cursor: pointer;
                transform: translateZ(0);

                &:hover {
                    color: $brand-primary;
                    text-decoration: none;
                }

                &:focus {
                    outline: 0;
                    text-decoration: none;
                }

                @media (max-width: 1023px) {
                    color: $gray-dark;
                    font-family: $ff-lato;
                }
            }
        }   
        
        &::before {
            content: none !important;
        }      
        
        .row {
            content: none !important;
        }
    }

    @media(min-width: $screen-md) {
        position: absolute;
        top: auto;
        left: 0;
        right: 0;
        margin-top: 10px;
        padding: em(35px, 18px) 0;
        z-index: 100;
        opacity: 0;
        visibility: hidden;
        transition: .3s all;
        font-size: 18px;
        box-shadow:  0 10px 25px 0 rgba(50, 50, 50, 0.4);
        
        &.is-open {
            margin-top: 0;
            opacity: 1;
            visibility: visible;

            .navbar__group--map & {
                @media (min-width: $screen-xl) {
                    margin-top: 20px;
                }
            }
        }

        & > ul {


            ul {
                padding: 0 $grid-gutter-width;

                li {
                    font-size: 1.2rem;
                }                

                a {
                    padding-left: em(20px, 18px);
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: auto;
                    transform: translateX(-#{$grid-gutter-width});
                    width: 1px;
                    height: 100%;
                    background-color: $gray-lighter;
                }

                &:first-child {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        a {
            &:before {
                content: '';
                position: absolute;
                top: 4px;
                left: -5px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 4px 0 4px 6px;
                border-color: transparent transparent transparent $brand-primary;
                opacity: 0;
                transition: all .3s;
            }

            &:hover {
                &:before {
                    left: 0;
                    opacity: 1;
                }
            }
        }
    }

    @media(max-width: $screen-sm-max) {
		display: none;

        &.is-open {
            display: block;
        }
	}
}

// ==========================================================================
// Socials
// ==========================================================================

// ==========================================================================
// Socials / Header
// ==========================================================================

.social {
  &--header {
    @extend %clearfix;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: none;

    &__title {
      display: block;
      float: left;
      padding: em(25px, 14px) em(25px, 14px) 0 0;
      font-size: em(14px);
      font-weight: 300;
      text-transform: uppercase;
    }

    &__button {
      position: relative;
      display: block;
      float: left;
      width: em(70px);
      height: 100%;
      border-left: 1px solid $gray-lighter;
      transition: background-color .3s;

      &:before {
        position: absolute;
        @extend %centerXY;
      }

      &:hover {
        background-color: darken($c-white, 10%);
      }

      &:focus,
      &:hover {
        color: $brand-primary;
        text-decoration: none;
      }
    }
    @media (min-width: $screen-md) {
      display: block;
    }
  }

  // ==========================================================================
  // Socials / Footer
  // ==========================================================================

  // ==========================================================================
  // Socials / Share
  // ==========================================================================

}

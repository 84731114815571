@media print {

    body {
        font-size: 16px;
    }

    section {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    #alert {
		display: none;
	}

	// navigation
	nav,
	.nav,
	.breadcrumb {
		display: none;
	}

	// buttons
	button,
	.button,
	.btn,
    .btn-icon {
		display: none;
	}

	// components
    .footer,
    .form-helpbar,
    .back-link {
        display: none;
    }

    .page__title {
        margin-bottom: 1rem;
    }

    // wysiwyg
    .typography--wysiwyg h2,
    .typography--wysiwyg .img {
        margin-bottom: 1rem;
    }

    .typography--wysiwyg p {
        margin-bottom: 0;
    }

	// links
	a[href]:after {
		content: none;
	}

}

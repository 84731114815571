// ==========================================================================
// Gallery
// ==========================================================================

.gallery {
    position: relative;
    margin-top: em($grid-gutter-width);
    text-align: center;

    &__title {
        @extend .h2;
        margin-top: 1.125rem;
        font-size: em(24px);
        font-weight: 600;

        a:hover {
            color: $brand-primary;
            text-decoration: none;
        }
    }

    img {
        max-width: 100%;
    }

    &__cover {
        position: relative;

        span {
            position: absolute;
            top: 45%;
            left: 50%;
            width: 100%;
            transform: translate(-50%, -50%);
            opacity: 0;
            font-family: $ff-alt;
            font-size: em(20px);
            font-weight: 600;
            color: $c-white;
            text-transform: uppercase;
            transition: all 300ms;
            z-index: 1;

            &:after {
                content: '+';
                position: absolute;
                bottom: em(-37px, 44px);
                @extend %centerX;
                font-size: em(44px);
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transition: all 300ms;
        }
    }

    &:hover {
        .gallery__cover {
            span {
                top: 50%;
                opacity: 1;
            }

            &:after {
                background-color: transparentize($c-base, .33);
            }
        }
    }

// ==========================================================================
// Gallery / Slider
// ==========================================================================

    &__slider {
        margin-bottom: 4.375rem;
        padding: 0 $grid-gutter-width / 2;

        .slick-slide {
            position: relative;
            outline: 0;
        }

        .slick-track {
            height: 250px;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
        }

        .slick-dots {
            position: absolute;
            bottom: -4.375rem;
            left: 0;
            width: 100%;
            @extend .bullets;

            button {
                width: 100%;
                height: 100%;
                text-indent: -9999px;
                outline: 0;
            }
        }

        @media (min-width: $screen-xs) {
            padding-left: 5rem;
            padding-right: 5rem;

            .slick-track {
                height: 375px;
            }
        }

        @media (min-width: $screen-sm) {
            .slick-track {
                height: 525px;
            }
        }
    }

    &__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        box-shadow: none;

        &:before {
            font-size: 1.5em;
        }
    }

    &__prev {
        left: 0;
        @extend %fa;
        @extend .fa-angle-left;
    }

    &__next {
        right: 0;
        @extend %fa;
        @extend .fa-angle-right;
    }
}


[notice-slider] {
    padding: 0 2.1rem;
    z-index: 9999;
    position: relative;
    .gallery__arrow {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        &::before {
            font-size: .9rem;
        }
    }
    .slick-slide {
        position: relative;
    }
}

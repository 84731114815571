// ==========================================================================
// Services
// ==========================================================================

.service {
  span {
    display: block;
    padding: 30px 0 25px 65px;
    font-family: $ff-alt;
    font-size: em(18px);
    text-transform: uppercase;

    &:before {
      position: absolute;
      top: 50%;
      left: em($grid-gutter-width/2, 34px);
      transform: translateY(-50%);
      font-size: em(34px, 18px);
      color: $brand-primary;
    }
  }

  &--no-icon {
    span {
        padding-left: 30px;

        &:before {
            content: none;
        }
    }
  }

  @media (min-width: $screen-xs) {
    position: relative;
    height: em(175px);
    text-align: center;

    span {
      position: absolute;
      top: em(100px, 18px);
      left: $grid-gutter-width/2;
      width: calc( 100% - #{$grid-gutter-width} );
      padding: 0;

      &:before {
        top: em(-55px, 38px);
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        transition: color 300ms;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: em(5px);
        left: 50%;
        transform: translateX(-50%) scale(0);
        width: em($s-border);
        height: 2px;
        background-color: $c-white;
        transition: all 300ms;
      }
    }

    &--no-icon {
      span {
          top: 50%;
          transform: translate3d(0, -50%, 0);
      }
    }

    &:hover {
      @include back-grad;
      box-shadow: inset 1px 1px 3px transparentize($c-base, .6);
      color: $c-white;

      span {
        &:before {
          color: $c-white;
        }

        &:after {
          bottom: em(-5px);
          transform: translateX(-50%) scale(1);
        }
      }
    }
  }
}

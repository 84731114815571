.calendar__legend--desktop {
	display: none;

	@media(min-width: $screen-sm-min) {
		display: block;
	}

	.calendar-filter-actions {
		height: auto;
		display: inline-flex;

		& > div {
			flex: 1 0 auto;
			height: auto;
		}

		span {
			font-size: .9rem;
			text-transform: none;
			width: 100%;
		}
	}

	.js-tag-label__text {
		transition: color 400ms ease;
	}

	@media(min-width: $screen-lg) {
		.js-tag-label:hover {
			.js-tag-label__text {
				color: $brand-primary;
			}
		}
	}
}

.calendar__legend {
	.js-tag-label {
		display: flex;
		align-items: center;
		text-transform: none;
		float: none;
	}

	.js-tag-label__text {
		flex-grow: 1;
	}
}
// ==========================================================================
// Buttons
// ==========================================================================

@mixin primary-button {
    @extend .btn-lg;
    @extend %back-grad;
    color: $btn-primary-color;
    border: 0;
    transition: all .3s linear;

    &:hover {
        color: $c-white;
        background-position: 0;
    }

    &:active {
        @extend %back-grad;
        background-position: 0;
        box-shadow: inset 0 1px 3px transparentize($c-base, .6);
    }

    &:focus {
        color: $c-white;
        box-shadow: inset 0 1px 3px transparentize($c-base, .6), 0 0 3px 3px transparentize($brand-primary, .4);
    }
}

.btn {
	text-transform: uppercase;
	font-family: $ff-alt;
    text-decoration: none;
	white-space: normal;

	&--default {
		@extend .btn-lg;
		border-color: $btn-default-border;
		background-color: $btn-default-bg;
	}

	&--small {
		@extend .btn-sm;
		border-color: $btn-default-border;
		background-color: $btn-default-bg;
	}

	&--primary {
        @include primary-button;
	}

	&:active:focus,
	&:focus {
		outline: 0;
	}

	&__group {
        .btn {
            @include rem('margin-bottom', $grid-gutter-width/4);

            & + .btn {
    			@include rem('margin-left', $grid-gutter-width/4);
    		}
        }

        &--center {
            @extend .btn__group;
            text-align: center;

            &--banner {
              margin-top: 75px;
            }

            &--no-banner {
              margin-bottom: 45px;
              text-align: center;
            }
        }
	}
}

[name="form_page_submit"] {
    text-transform: uppercase;
    font-family: $ff-alt;
    text-decoration: none;
    white-space: normal;

    &:active:focus,
    &:focus {
    outline: 0;
    }

    @include primary-button;
}

// ==========================================================================
// Buttons / Icons
// ==========================================================================

.btn-icon {
	position: relative;
	@extend .btn--primary;
	@include rem('font-size', 34px);
  	line-height: em(36px, 34px);
  	padding: 0;
	width: em(66px, 34px);
	height: em(66px, 34px);
  	text-align: center;
	border-radius: 100%;
	box-shadow: inset 1px 1px 2px transparentize($c-base, .6);

	&:active:focus,
	&:focus {
		outline: 0;
	}

	&:active,
	&:focus {
		box-shadow: inset 1px 1px 2px transparentize($c-base, .6);
	}

	&[class*=' fa']:before,
	&[class^='fa']:before {
		display: inline-block;
	  font: normal normal normal 14px/1 FontAwesome;
	  font-size: inherit;
	  text-rendering: auto;
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	}

	&.page-scroll:before {
		line-height: 1.8;
	}

	&--map {
		span {
			color: $c-base;
		}
	}

	&--white {
		color: $brand-secondary;
		background-color: $c-white;
		background-image: linear-gradient(45deg, #ffffff 25%, #f4eeeb 25%, #f4eeeb 50%, #ffffff 50%, #ffffff 75%, #f4eeeb 75%, #f4eeeb 100%);
		background-size: 200%;
		background-position: 50%;
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4eeeb', endColorstr='#ffffff',GradientType=1 );
		transition: background-position .3s;
		box-shadow: none;

		&:hover {
			color: $brand-secondary;
			background-position: 150%;
		}

		&:active,
		&:focus {
			box-shadow: none;
		}

		&:active {
			background-image: linear-gradient(45deg, #ffffff 25%, #f4eeeb 25%, #f4eeeb 50%, #ffffff 50%, #ffffff 75%, #f4eeeb 75%, #f4eeeb 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4eeeb', endColorstr='#ffffff',GradientType=1 );
			background-position: 150%;
		}

		&:focus {
			color: $brand-secondary;
		}

		&:after {
			content: '';
			position: absolute;
			top: -9px;
			left: -9px;
			right: -9px;
			bottom: -9px;
			width: auto;
			height: auto;
			z-index: 0;
			opacity: 1;
			border: 2px dotted $c-white;
			border-radius: 100%;
			background-color: transparent;
			background-image: none;
			filter: none;
		}

		span {
			color: $c-white;
		}
	}

	&--small {
		width: em(60px, 34px);
		height: em(60px, 34px);
		box-shadow: none;
	}


	&--map {
		@include rem('font-size', 26px);
		width: em(60px, 26px);
		height: em(60px, 26px);
		line-height: 1em;
		@extend .fc;
    	@extend .fc-icon_carte_interactive;
		box-shadow: none;

		&:active,
		&:focus {
			box-shadow: none;
		}

		span {
			position: absolute;
      		top: calc( 100% + 18px );
			@extend %centerX;
      		@include rem('font-size', 14px);
      		text-transform: uppercase;
			white-space: nowrap;
            display: none;

            @media (min-width: $screen-sm) {
              display: block;
            }
        }

        & + span.mobile {
            display: block;
            margin-top: 1rem;

            @media (min-width: $screen-sm) {
              display: none;
            }
        }

		&--inline {
            display: inline-block;
			width: em(60px, 26px);
			height: em(60px, 26px);
			padding: 0;

			span {
				top: 50%;
				transform: translateY(-50%);
				left: calc( 100% + 20px );
			}

            &:before {
                line-height: em(60px, 26px);
            }
		}
	}
}

a.btn-icon--small {
    &[class*=' fa']:before,
    &[class^='fa']:before {
        line-height: 1.8em;
    }
}

a.btn-icon, a.map-icon {
	text-decoration: none;
}



.dynamic-cta-wrapper {
	text-align: center;
	margin: 1rem 0;

	a {
		font-size: 1rem;
		@media (min-width: $screen-sm) {
			font-size: 1.3rem;
		}
	}
	
	@media (min-width: $screen-sm) {
		display: inline-flex;
		margin-right: 1rem;
	}
}

.btn-map {
	display: flex;
	align-items: center;
	margin: 1rem 0;
	flex-direction: column;
	cursor: pointer;

	span {
		margin-top: 1rem;
	}	

	@media (min-width: $screen-sm) {
		flex-direction: row;
		align-items: flex-start;

		span {
			margin-left: 1rem;
		}
	}	

	.btn-icon {
		display: flex;
		justify-content: center;
		align-items: center;
	}


	&:hover,
	&:active,
	&:visited {
		text-decoration: none;
	}
}
// ==========================================================================
// Tabs / Accordion
// ==========================================================================

.tab {
  position: relative;
  padding: em(25px) 0 em(12px);
  overflow: hidden;
  clear: both;

  &:before,
  &:after {
      content: '.................................................................................................................................................................................................................................................................................................................................';
      position: absolute;
      left: 0;
      font-size: em(18px);
      color: lighten($gray-base, 40);
      letter-spacing: 1px;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  & + .tab,
  & + [data-open-anchor] + .tab {
      padding-top: 0;

      &:before {
        display: none;
      }
  }

  &__heading {
    position: relative;
    padding: 0 em($panel-heading-padding);
    color: $brand-primary;

    &:before {
      position: absolute;
      top: 46%;
      transform: translateY(-50%);
      left: em(10px, 20px);
      @extend %fa;
      @extend .fa-angle-right;
      font-size: em(20px);
      transition: transform 300ms;
      transform-origin: center;
    }

    &[aria-expanded="true"] {
      &:before,
      .tab__title:after {
        transform: translate3D(0, -50%, 0) rotate(90deg);
      }

      .tab__title:before {
        transform: translate3D(0, -50%, 0) rotate(180deg);
      }
    }
  }

  &__title {
    position: relative;
    padding: em($panel-heading-padding - 5) em($grid-gutter-width);
    cursor: pointer;
  
    @media (max-width: $screen-sm) {
      font-size: 1rem;
    }


    &:before,
    &:after {
      content: '';
      position: absolute;
      @extend %centerY;
      display: block;
      transform: translate3D(0, -50%, 0) rotate(0);
      background-color: $brand-primary;
      transition: all 300ms;
    }

    &:before {
      right: em(10px);
      width: em(12px);
      height: em(2px);
    }

    &:after {
      right: em(15px);
      width: em(2px);
      height: em(12px);
    }
  }

  &__content {
    @extend %clearfix;
    padding: em(15px);
    color: $gray-light;


    @media (max-width: $screen-sm) {
      font-size: 1rem;
    }    

		@media (min-width: $screen-sm) {
			padding: em($panel-body-padding);
    }
    
    .tab__content {
      padding-left: 0;
      padding-right: 0;
    }
  }
}


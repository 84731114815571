.calendar__container {
	@extend .container-fluid;
	background: none;
	padding-top: 0;
	padding-bottom: 0;
	margin-top: 0;
	margin-bottom: 0;
	border-top: 1px solid #dddddd;
	}

	#calendar {
	//@extend .visible-sm;
	//@extend .visible-md;
	//@extend .visible-lg;
	//@extend .visible-xl;
	}

	.calendar__legend {
		.js-tag-label {
			width: 100%;
		}
	}

	.calendar__sidebar {
		@extend .col-sm-6;
		@extend .col-lg-4;

		@media (min-width: 1440px) {
			width: 30%;
		}

		padding: 15px 15px 0 15px;
		background: #fff;
		.tag.is-active {
			&:before {
				display: none;
			}
		}

		.tag.eventual {
			cursor: pointer;
		}

		@media(min-width: $screen-sm-min) {
			padding: 36px 36px 0 36px;
		}			
	}

	.calendar__main {
	@extend .col-sm-6;
	@extend .col-lg-8;

	@media (min-width: 1440px) {
		width: 70%;
	}
	
	@media (min-width: 768px) {
		padding-left: 70px;
	}

	@media (min-width: 1024px) {
		padding-left: 43px;
	}

	> .container-fluid {
		background: none;
		margin-top: 0;
		
		@media (max-width: 768px) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.page__title.page__title--main {
		font-size: 24px;
		color: #4E4E4E;
		font-weight: 900;
		text-align: center;
		margin-top: 60px;
		margin-bottom: 0;
		@media (min-width: 768px) {
			margin-top: 0;
			text-align: left;
			margin-top: 68px;
		}

		&:after {
		display: none;
		}
	}

	.list--events {
		li {
		border: 1px solid #DDDDDD;
		position: relative;
		padding: 0;
		background: #fff;
		margin-bottom: 30px;
		overflow: visible;

		.wrap {
			padding: 0;
			flex-wrap: wrap;
			display: flex;
			align-content: start;
			align-items: start;
			background: none;

			@media (min-width: 768px) {
				min-height: 190px;
			}

			@media (min-width: 1200px) {
				align-content: center;
				align-items: center;
			}

		}

		a.wrap {
			cursor: pointer;
			text-decoration: none;
		}

		.date,
		.image,
		.content {
			display: block;
			flex-basis: 0;
		}

		.date {
			text-align: center;
			flex-basis: auto;
			width: 60px;
			padding: 5px;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			position: absolute;
			z-index: 2;

			& + .content {
				padding-top: 76px; 

				@media (min-width: 768px) {
					padding-top: 38px;
				}				
			}
			
			span {
				display: block;
				font-size: 16px;
				color: #fff;
				line-height: 1;

				@media (min-width: 768px) {
					font-size: 24px;
				}

				&:first-child {

					font-size: 25px;
	
					@media (min-width: 768px) {
						font-size: 42px;
					}					
				}
			}

			@media (min-width: 768px) {
				width: (43*2px);
				height: (43*2px);
				margin-left: -43px;
				margin-top: 43px;				
				flex-basis: 0;
				padding: 0;
			}

			@media (min-width: 1200px) {
				margin-top: 0;
				top: 50%;
				transform: translateY(-50%);
			}			
		}

		.image {
			min-width: 100%;
			min-height: 190px;
			background-size: cover;

			@media (min-width: 1200px) {
				min-width: 25%;
			}

			& + .content {
				padding-left: 15px;

				@media (min-width: 768px) {
					padding-left: 38px;
				}				
			}
		}

		.content {
			padding: 15px;
			width: 100%;
			align-self: center;
			min-width: 100%;
			
			@media (min-width: 768px) {
				padding: 38px;
				padding-left: calc(38px + 43px);
			}

			@media (min-width: 1200px) {
				min-width: 75%;
			}

			p {
				color: #4E4E4E;
				font-size: 1em;
				margin-bottom: 0;

				& + p {
					margin-top: 5px;
					margin-bottom: 0;
				}
			}
		}
		}
	}
	}

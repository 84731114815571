.navbar {
	&__group {
		@extend %clearfix;

		&.is-fixed {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: 1030;
		}

		&:not(.navbar__group--home) {
			.navbar__top {
				position: relative;
			}
		}

		@media(min-width: $screen-md) {
			top: -200px;
			transition: top 500ms;

			&--home {
				top: -100px;

				&.is-fixed {
					.navbar__top,
					.navbar__main {
						position: relative;
					}
				}
		    }

			&:not(.navbar__group--home) {
				&.is-fixed {
					top: -123px;

					.navbar__top {
						padding: 0;
					}

					.navbar__top,
					.navbar__main {
						width: calc( 100% - #{$grid-gutter-width/2} );
						max-width: 1500px;
						margin-left: auto;
						margin-right: auto;
					}

					.navbar__nav {
						margin-right: 0;
					}
				}
			}
		}

		@media(min-width: $screen-lg) {
			&:not(.navbar__group--home) {
				&.is-fixed {
					top: -100px;
				}
			}
		}

        @media(min-width: $screen-xl) {
            &:not(.navbar__group--home) {
                &.is-fixed {
					.navbar__top,
					.navbar__main {
						width: calc( 100% - #{$grid-gutter-width} );
					}
				}
			}
        }
	}

// ==========================================================================
// Navbars / Top
// ==========================================================================

	&__top {
		@extend %clearfix;

	  .navbar__group--home & {
	    position: absolute;
	  	top: 0;
	  	left: 0;
	  	right: 0;
	  	z-index: 100;
	  }
	}

// ==========================================================================
// Navbars / Main
// ==========================================================================

	@media(min-width: $screen-md) {
		&__main {
			.navbar__group--home & {
				position: absolute;
				bottom: 230px;
				transform: translateY(-50%);
				left: 0;
			  	right: 0;
			    z-index: 100;
			}

			.navbar__group--home.is-fixed & {
		  		transform: translateY(0);
		  	}
		}

		&__nav {
			display: table;
			margin-left: auto;
			margin-right: auto;
			clear: right;

			& > li {
				display: table-cell;
			}
		}
	}
	
	@media(min-width: $screen-lg) {
		&__main {
			.navbar__group--home & {
				bottom: 298px;
			}
		}
	}	

    &__main {
        .navbar__group--home.is-fixed & {
            bottom: 0;
        }
    }

}
